import React, { useState } from 'react';
import styles from './SingleItemLinkCarousel.module.scss';
import Container from 'components/atoms/InfoHubContainerSection';
import SliderContainer from 'components/atoms/SliderContainer/SliderContainer';
import { CloseI, EditI, AddI, SaveI, Button, Loader } from 'components/ui';
import { handleAsync } from 'helpers';
import { showSuccessToast } from 'utils/toast';
import Options from 'components/atoms/Options/Options';
import { useSelector } from 'react-redux';
import { getIsAdminSelector } from 'store/login.slice';
import { Modal } from 'components/modals';
import { FileUploader, Textarea } from 'components/form';
import { useUploadBlob } from 'hooks';
import { getS3Url } from 'helpers';
import { showErrorToast } from 'utils/toast';
import InfoHubTitle from 'components/atoms/InfoHubTitle';
import { showWarnToast } from 'utils/toast';
import { useTranslation } from 'react-i18next';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { APP_TIERS } from 'constants';

export default function SingleItemLinkCarousel({
  data,
  title,
  subTitle,
  companyId,
  updateMutation,
  colorBgSwitcher,
  isLoading,
  ...rest
}) {
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const [add, setAdd] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [dataToBeUpdated, setDataToBeUpdated] = useState({});
  const [dataToBeAdded, setDataToBeAdded] = useState({});
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const { uploadBlob, isUploading } = useUploadBlob();
  const isAdmin = useSelector(getIsAdminSelector);
  const { tierData } = useGetUserTierData();
  const userTier = tierData?.tier;

  const handleAdd = async () => {
    let currentData = [];
    let saveData = { link: dataToBeAdded?.link, title: dataToBeAdded?.title };
    if (thumbnailFile) {
      const [status, result] = await handleAsync(uploadBlob(thumbnailFile));
      if (!status) {
        const error = typeof result === 'string' ? result : t('Error uploading thumbnail');
        return showErrorToast(error);
      }
      saveData.thumbnail = result;
    }
    if (data) {
      currentData = data ? [...data] : [];
    }
    currentData.push({
      link: saveData?.link,
      thumbnail: saveData?.thumbnail,
      title: saveData?.title
    });
    showSuccessToast(t('Saving'));
    handleAsync(
      updateMutation({
        companyId: companyId,
        dataToBeSaved: currentData
      }).unwrap()
    );
    setDataToBeAdded((prev) => (prev = {}));
    setThumbnailFile(null);
    showSuccessToast('Done!');
  };

  const handleUpdate = async () => {
    let currentData = [];
    let saveData = {
      link: dataToBeUpdated.link,
      thumbnail: dataToBeUpdated.thumbnail,
      title: dataToBeUpdated.title
    };
    if (thumbnailFile) {
      const [status, result] = await handleAsync(uploadBlob(thumbnailFile));
      if (!status) {
        const error = typeof result === 'string' ? result : t('Error uploading thumbnail');
        return showErrorToast(error);
      }
      saveData.thumbnail = result;
    }

    if (data) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      currentData = data ? [...data] : [];
    }

    currentData[dataToBeUpdated.index] = {
      link: saveData?.link,
      thumbnail: saveData?.thumbnail,
      title: saveData?.title
    };

    showSuccessToast(t('Updating'));
    handleAsync(
      updateMutation({
        companyId: companyId,
        dataToBeSaved: currentData
      }).unwrap()
    );
    setThumbnailFile(null);
    showSuccessToast(t('Done'));
  };

  const removeData = async (saveData) => {
    let currentData = [];

    if (data) {
      currentData = data ? [...data] : [];
    }

    for (let i = 0; i < currentData.length; i++) {
      if (currentData[i] == saveData) {
        currentData.splice(i, 1);
      }
    }

    showSuccessToast(t('Removing'));
    const result = await handleAsync(
      updateMutation({
        companyId: companyId,
        dataToBeSaved: currentData
      }).unwrap()
    );
    if (result[0]) {
      showSuccessToast(t('Done'));
    }
  };
  if (isLoading || isUploading) {
    return (
      <Container>
        <div className={styles.headLiner}>
          <InfoHubTitle title={title} />
        </div>
        <Loader />
      </Container>
    );
  }
  return (
    <div
      className={`${styles.container} ${colorBgSwitcher ? styles[`bg-${colorBgSwitcher}`] : ''}`}
    >
      <Container>
        <div className={styles.headLiner}>
          <InfoHubTitle title={title} />
          {edit ? (
            <div className={styles.save}>
              <SaveI onClick={() => setEdit(false)} />
            </div>
          ) : (
            <>
              {isAdmin && userTier?.toLowerCase() === APP_TIERS.REGULAR && (
                <Options handleEdit={() => setEdit(!edit)} />
              )}
            </>
          )}
        </div>
        <p className={styles.desc}>{subTitle}</p>
        <SliderContainer breakpoint={1440} breakpointSlide={1} slides={1}>
          {data?.length &&
            data.map((item, i) => {
              return (
                <div className={styles.bottomData} key={i}>
                  {edit && (
                    <CloseI
                      onClick={() => {
                        removeData(item);
                      }}
                    />
                  )}
                  {edit && (
                    <EditI
                      onClick={() => {
                        setOpenUpdateModal(true);
                        setDataToBeUpdated({ ...item, index: i });
                      }}
                    />
                  )}
                  <a
                    style={{
                      cursor: 'pointer'
                    }}
                    rel="noreferrer"
                    target="_blank"
                    href={item?.link}
                  >
                    <p className={styles.itemTitle}>{item.title}</p>
                    <img
                      className={styles.itemImage}
                      src={getS3Url(item.thumbnail)}
                      alt="thumbnail image"
                    />
                  </a>
                </div>
              );
            })}
        </SliderContainer>
        {edit && (
          <div className={styles.addButton}>
            <AddI
              onClick={() => {
                setAdd(true);
              }}
            />
          </div>
        )}
      </Container>
      <Modal
        isModalOpen={add}
        onClose={() => {
          setAdd(false);
        }}
        hasHeader
        colorBgSwitcher={colorBgSwitcher}
      >
        <div className={styles.FeaturedVideosModalContainer}>
          <Textarea
            onChange={({ target: { value } }) => {
              const val = value.substring(0, 150);
              if (value.length > 150) {
                showWarnToast(t('Title should be less than 150 characters'));
              }
              setDataToBeAdded({ ...dataToBeAdded, title: val });
            }}
            placeholder={t('Add title')}
            value={dataToBeAdded.title}
          />

          <Textarea
            onChange={({ target: { value } }) => {
              setDataToBeAdded({ ...dataToBeAdded, link: value });
            }}
            placeholder={t('Add link')}
            value={dataToBeAdded.link}
          />
          <FileUploader
            onChange={(files) => {
              const file = files[0];
              setThumbnailFile(file);
            }}
            text={t('Upload Thumbnail')}
          />

          <div className={styles.FeaturedVideosModalButtonContainer}>
            <Button
              onClick={() => {
                handleAdd();
                setAdd(false);
              }}
              colorBgSwitcher={colorBgSwitcher}
            >
              {t('Save')}
            </Button>
            <Button onClick={() => setAdd(false)} colorBgSwitcher={colorBgSwitcher}>
              {t('Close')}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        isModalOpen={openUpdateModal}
        onClose={() => {
          setOpenUpdateModal(false);
        }}
        hasHeader
        colorBgSwitcher={colorBgSwitcher}
      >
        <div
          style={{
            padding: '1rem'
          }}
        >
          <Textarea
            onChange={({ target: { value } }) => {
              const val = value.substring(0, 150);
              if (value.length > 150) {
                showWarnToast(t('Title should be less than 150 characters'));
              }
              setDataToBeUpdated({ ...dataToBeUpdated, title: val });
            }}
            placeholder={t('Add title')}
            value={dataToBeUpdated.title}
          />

          <Textarea
            onChange={({ target: { value } }) => {
              setDataToBeUpdated({ ...dataToBeUpdated, link: value });
            }}
            placeholder={t('Add link')}
            value={dataToBeUpdated.link}
          />
          <FileUploader
            onChange={(files) => {
              const file = files[0];
              setThumbnailFile(file);
            }}
            text={t('Edit Thumbnail')}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1rem',
              gap: '.5rem'
            }}
          >
            <Button
              onClick={() => {
                handleUpdate();
                setOpenUpdateModal(false);
              }}
              colorBgSwitcher={colorBgSwitcher}
            >
              {t('Update')}
            </Button>
            <Button onClick={() => setOpenUpdateModal(false)} colorBgSwitcher={colorBgSwitcher}>
              {t('Close')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
const initialLinks = [
  {
    link: 'http://google.com',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/freelancer_ix6yzb.webp',
    title: 'UI design'
  },
  {
    link: '',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/freelancer_ix6yzb.webp',
    title: 'Fullstack development'
  },
  {
    link: '',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/freelancer_ix6yzb.webp',
    title: 'Game development'
  }
];
