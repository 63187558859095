import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import styles from './Chapter.module.scss';
import { FaPencil, FaRegTrashCan } from 'react-icons/fa6';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { addLesson, deleteItem, renameItem } from 'store/course.slice';
import Lesson from '../Lesson/Lesson';
import { randomUUId } from 'helpers/general';

const new_lesson_obj = {
  id: null,
  action: '',
  title: 'New Lesson',
  duration: 0,
  content: []
};

const Chapter = ({ title, lessons, id }) => {
  const [collapseBody, setCollapseBody] = useState(false);
  const [editable, setEditable] = useState(false);
  const dispatch = useDispatch();

  const toggleCollapsible = () => {
    setCollapseBody(!collapseBody);
  };

  const handleAddLesson = () => {
    dispatch(addLesson({ chapterId: id, lesson: { ...new_lesson_obj, id: randomUUId() } }));
  };

  const deleteChapter = (e) => {
    e.stopPropagation();
    dispatch(deleteItem({ itemId: id, itemType: 'chapter' }));
  };

  const editChapterName = (e) => {
    e.stopPropagation();
    setEditable(true);
  };

  const handleTitleChange = (e) => {
    const title = e.target.value;
    dispatch(renameItem({ itemId: id, itemType: 'chapter', newTitle: title }));
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      setEditable(false);
    }
  };

  const videoExtensions = new Set(['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv']);

  const totalVideoCount = lessons.reduce((total, lesson) => {
    return (
      total +
      lesson.content.filter((item) => videoExtensions.has(item.fileDetails.extension)).length
    );
  }, 0);

  return (
    <div className={styles.container}>
      <div className={styles.lesson_header} onClick={toggleCollapsible}>
        <div className={styles.header_left}>
          {collapseBody ? <IoIosArrowDown size={18} /> : <IoIosArrowUp size={18} />}
          {editable ? (
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              onBlur={() => setEditable(false)}
              onKeyUp={handleKeyUp}
              autoFocus
            />
          ) : (
            <>
              {title}
              <div className={styles.icon}>
                <FaPencil onClick={editChapterName} />
              </div>
            </>
          )}
        </div>
        <div className={styles.header_right}>
          <span>
            {lessons.length} {lessons.length === 1 ? 'Lesson' : 'Lessons'}
          </span>
          <span> | </span>
          <span>{totalVideoCount} Videos</span>
          <FaRegTrashCan style={{ cursor: 'pointer' }} onClick={deleteChapter} />
        </div>
      </div>
      {!collapseBody && (
        <div className={styles.lesson_body}>
          {lessons && lessons.map((lesson, index) => <Lesson {...lesson} key={lesson.id} />)}
          <div aria-hidden="true" className={styles.add} onClick={handleAddLesson}>
            <FaPlus /> Add Lesson
          </div>
        </div>
      )}
    </div>
  );
};

export default Chapter;
