import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import ResultItem from '../ResultItem';
import { useNavigate } from 'react-router-dom';
import { answerTypes, previewStages, setPreviewStage } from 'store/quiz.slice';
import { IoMdStopwatch } from 'react-icons/io';
import SummaryStats from '../SummaryStats';
import QuestionIndicators from '../QuestionIndicators';
import { isAnswerCorrect } from 'helpers/quiz';

const QuizSummary = () => {
    const { title, description, quizData, answers, timeSpent } = useSelector(state => state.quiz);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        multiChoice,
        textAns,
    } = answerTypes

    const minutes = Math.floor(timeSpent / 60);
    const seconds = timeSpent % 60;
    const timeTaken = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`

    const results = quizData.map((question, index) => {
        const userAnswer = answers[index];
        const isCorrect = isAnswerCorrect(question, userAnswer);

        const participantAnswer =
            question.type === multiChoice
                ? userAnswer?.map(ans => ans.text).join(', ')
                : userAnswer?.text || userAnswer;

        const correctAnswer =
            question.type === multiChoice
                ? question.answer?.[multiChoice]?.map(id =>
                    question.options.find(opt => opt.id === id)?.text
                ).join(', ')
                : question.type === textAns ? question.answer[textAns] : question.options.find(opt => opt.id === question.answer?.[question.type])?.text || '';

        return {
            question: question.question,
            type: question.type,
            participantAnswer,
            correctAnswer,
            isCorrect,
            options: question.options,
        };
    });

    const gotToQuestions = () => {
        navigate('/quiz');
    }

    const gotToResults = () => {
        dispatch(setPreviewStage(previewStages.results));
    }

    return (
        <div className={styles.quizSummaryWrapper}>
            <div className={styles.quizResultContainer}>
                <div className={styles.quizResultsContainer}>
                    <div className={styles.header}>
                        <h2 className={styles.title}>{title}</h2>
                        <div className={styles.timeInfo}>
                            <IoMdStopwatch size={18} color="#999" />
                            <span>Done in {timeTaken}</span>
                        </div>
                    </div>
                    {/* <p className={styles.description}>{description}</p> */}

                    <SummaryStats results={results} />
                    <QuestionIndicators results={results} />

                    <div className={styles.separator} />

                    <div className={styles.resultsList}>
                        {results.map((result, index) => (
                            <ResultItem key={index} index={index + 1} result={result} />
                        ))}
                    </div>

                    <div className={styles.actions}>
                        <button onClick={gotToResults} className={styles.detailsButton}>
                            Back to results
                        </button>
                        <button onClick={gotToQuestions} className={styles.backButton}>
                            Back to Questions
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default QuizSummary;
