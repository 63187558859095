import React from 'react';
import styles from './EditSection.module.scss';
import Options from '../Options/Options';
export default function EditSection({
  edit,
  onEditClicked,
  onCancelClick,
  save,
  onSaveClick,
  colorBgSwitcher
}) {
  return (
    <div
      className={`${
        edit != true ? styles.editSection : save ? styles.ActiveEditwithSave : styles.ActiveEdit
      } ${colorBgSwitcher ? styles[`bg-${colorBgSwitcher}`] : ''}`}
    >
      <Options
        handleEdit={() => {
          onEditClicked();
        }}
        edit={edit}
        handleSave={() => {
          onSaveClick();
        }}
        handleDiscard={() => {
          onCancelClick();
        }}
      />
    </div>
  );
}
