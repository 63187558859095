import React, { useRef, useState } from 'react';
import styles from './ResultSharePostModal.module.scss';
import { Input } from 'components/form';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import Copy from 'assets/icons/copy.svg?react';
import {
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share';
import { fallbackCopyTextToClipboard } from 'helpers/general';
const ResultSharePostModal = ({ open, value, onClose }) => {
  const inputRef = useRef(null);
  const [isLinkCopied, setLinkCopied] = useState(false);

  const handleCopyClick = async () => {
    if (inputRef.current) {
      const textToCopy = inputRef.current.value;

      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(textToCopy);
        setLinkCopied(true);
      } else {
        fallbackCopyTextToClipboard(textToCopy, () => {
          setLinkCopied(true);
        });
      }
    }
  };

  if (!open) return null;

  return (
    <CreateModal handleClose={onClose} textCancel="Close" showSave={false}>
      <div className={styles.shareLinkModal}>
        <h1>Share Post</h1>
        <Input
          label="Sharing Link"
          disabled
          type="text"
          extraClass="teamInput"
          value={value}
          name="passKey"
          onChange={(e) => {}}
          icon={<Copy />}
          inputRef={inputRef}
          clickEvent={handleCopyClick}
          tooltip={isLinkCopied ? 'Link Copied' : 'Copy Link'}
        />
        <div className={styles.buttonsOpened}>
          <FacebookShareButton url={value}>
            <FacebookIcon round={true} size={40} />
          </FacebookShareButton>
          <WhatsappShareButton url={value}>
            <WhatsappIcon round={true} size={40} />
          </WhatsappShareButton>
          <TwitterShareButton url={value}>
            <TwitterIcon round={true} size={40} />
          </TwitterShareButton>
          <EmailShareButton url={value}>
            <EmailIcon round={true} size={40} />
          </EmailShareButton>
        </div>
      </div>
    </CreateModal>
  );
};

export default ResultSharePostModal;
