import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import loginSlice from './login.slice';
import profileSlice from './profile.slice';
import individualProfile from './individualProfile.slice';
import { apiSlice } from 'api/apiSlice';
import socialBoardSlice from './socialBoard.slice';
import courseReducer from './course.slice';
import quizReducer from './quiz.slice';
import menuReducer from './menu.slice'; // Import the menu reducer

const apiMiddleware = () => (next) => async (action) => {
  if (!navigator.onLine) return;
  else return next(action);
};

const menuPersistConfig = {
  key: 'menu',
  storage
};

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  individualProfile,
  user: loginSlice,
  profile: profileSlice,
  socialBoard: socialBoardSlice,
  menu: persistReducer(menuPersistConfig, menuReducer), // Persisted menu slice,
  course: courseReducer,
  quiz: quizReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(apiSlice.middleware)
      .concat(apiMiddleware)
  // devTools: import.meta.env.MODE !== 'production'
});

export const persiststore = persistStore(store);
