import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styles from './styles.module.scss';
import Arrow from 'assets/icons/arrow.svg?react';
import { Button } from 'components/ui';
import { useNavigate } from 'react-router-dom';
import QuizAnswerType from 'components/molecules/QuizAnswerType/QuizAnswerType';
import QuizOptions from 'components/molecules/QuizOptions/QuizOptions';
import { GoTrash } from 'react-icons/go';
import { answerTypes, previewStages, setPreviewStage, setQuizData } from 'store/quiz.slice';

export default function Index() {
  const navigate = useNavigate();
  const [dropdowns, setDropdowns] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isContentVisible, setIsContentVisible] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [optionsByType, setOptionsByType] = useState([]);
  const [hints, setHints] = useState([]);
  const [answers, setAnswers] = useState([]);

  const {
    singleChoice,
  } = answerTypes

  const {quizData: reduxQuizData, title, description} = useSelector((state) => state.quiz);

  const dispatch = useDispatch();

  useEffect(() => {
    if(reduxQuizData.length > 0) {
      setDropdowns(reduxQuizData.map((_ => [])));
      setSelectedOptions(reduxQuizData.map((item => item.type)));
      setIsContentVisible(reduxQuizData.map(_ => true));
      setQuestions(reduxQuizData.map(item => item.question));
      setHints(reduxQuizData.map(item => item.hint));
      setOptionsByType(reduxQuizData.map(item => item.options));
      setAnswers(reduxQuizData.map(item => item.answer));
    }
  }, [reduxQuizData])

  const handleOptionSelect = (option, index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = option;
    setSelectedOptions(newSelectedOptions);
  };

  const handleQuestionChange = (value, index) => {
    const newQuestions = [...questions];
    newQuestions[index] = value;
    setQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    setDropdowns([...dropdowns, dropdowns.length]);
    setSelectedOptions([...selectedOptions, singleChoice]);
    setIsContentVisible([...isContentVisible, true]);
    setQuestions([...questions, '']);

    setOptionsByType([...optionsByType, []]);
    setHints([ ...hints, '' ]);
  };

  const handleHintChange = (value, index) => {
    const newHints = [...hints];
    newHints[index] = value;
    setHints(newHints);
  };

  const handleAnswerChange = (index, answer) => {
    const newAnswers = [...answers];
    newAnswers[index] = {[selectedOptions[index]]: answer};    
    setAnswers(newAnswers);
  };

  const handleOptionChange = (index, options) => {
    const newOptionsByType = [...optionsByType];
    newOptionsByType[index] = options;
    setOptionsByType(newOptionsByType);
  };

  const toggleContentVisibility = (index) => {
    const newIsContentVisible = [...isContentVisible];
    newIsContentVisible[index] = !newIsContentVisible[index];
    setIsContentVisible(newIsContentVisible);
  };

  const handleDeleteQuestion = (index) => {
    setDropdowns(dropdowns.filter((_, i) => i !== index));
    setSelectedOptions(selectedOptions.filter((_, i) => i !== index));
    setIsContentVisible(isContentVisible.filter((_, i) => i !== index));
    setQuestions(questions.filter((_, i) => i !== index));

    setOptionsByType(optionsByType.filter((_, i) => i !== index));
    setHints(hints.filter((_, i) => i !== index));
  };

  const validateQuizData = () => {
    if (!title) {
      toast.error('Please add a title.')
      return false;
    }
    if (!description) {
      toast.error('Please add a description.')
      return false;
    }
    if (!questions.length) {
      toast.error('Please add at least one question.')
      return false;
    }

    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      const answer = answers[i]?.[selectedOptions[i]];
  
      if (!question) {
        toast.error(`Please add a question for Qn ${i + 1}.`);
        return false;
      }
  
      if (!answer || answer.length === 0) {
        toast.error(`Please add an answer for Qn ${i + 1}.`);
        return false;
      }
    }

    return true;
  };
  
  const generateQuizData = () =>
    dropdowns.map((_, index) => ({
      question: questions[index] || '',
      type: selectedOptions[index] || 'text',
      options: optionsByType[index] || [],
      hint: hints[index] || null,
      answer: answers[index] || null,
    }));

  const saveQuizData = () => {
    if (!validateQuizData()) return;
    
    const quizData = generateQuizData();
    dispatch(setQuizData({ quizData }));
    navigate('/quiz/settings')
  };

  const previewQuiz = () => {
    if (!validateQuizData()) return;

    const quizData = generateQuizData();  
    dispatch(setQuizData({ quizData }));
    dispatch(setPreviewStage(previewStages.description));
    navigate('/quiz/preview', { state: quizData })
  }

  return (
    <div className={styles.container}>
      {dropdowns.map((dropdown, index) => {        
        return (
        <div className={styles.dropdown} key={dropdown}>
          <div className={styles.topContainer}>
            <div className={styles.top}>
              <div className={styles.inputContainer}>
                <span>Q</span>
                <p>Question:</p>
                <input
                  type="text"
                  value={questions[index] || ''}
                  onChange={(e) => handleQuestionChange(e.target.value, index)}
                  placeholder="Your question goes here"
                />
              </div>
              <button onClick={() => toggleContentVisibility(index)}>
                <Arrow />
              </button>
            </div>
            <button onClick={() => handleDeleteQuestion(index)} className={styles.deleteBtn}>
              <GoTrash />
            </button>
          </div>
          {isContentVisible[index] && (
            <div className={styles.content}>
              <QuizAnswerType
                selectedType={selectedOptions[index]}
                onSelectType={(type) => handleOptionSelect(type, index)}
              />
            </div>
          )}
          <div className={styles.selectedType}>
            <QuizOptions
              options={optionsByType[index]}
              selectedType={selectedOptions[index]}
              onChangeOptions={(options) => handleOptionChange(index, options)}
              hint={hints[index]}
              onHintChange={(value) => handleHintChange(value, index)}
              onAnswerChange={(answer) => handleAnswerChange(index, answer)}
              answer={answers[index] && answers[index][selectedOptions[index]]}
            />
          </div>
        </div>
      )})}
      <div aria-hidden="true" onClick={handleAddQuestion} className={styles.addQuestion}>
        <p>Add Question</p>
        <span>+</span>
      </div>
      {dropdowns?.length > 0 && (
        <div className={styles.buttons}>
          <Button bgColor={'#434343'} color={'white'}>
            Cancel
          </Button>
          <Button bgColor={'rgb(58, 128, 105)'} color={'white'} onClick={previewQuiz}>
            Preview
          </Button>
          <Button onClick={saveQuizData} bgColor={'#046799'} color={'white'}>
            Save & Continue
          </Button>
        </div>
      )}
    </div>
  );
}
