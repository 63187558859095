import { AiOutlinePlaySquare } from "react-icons/ai";
import { IoIosLink, IoMdImage } from "react-icons/io";
import { IoDocumentText } from "react-icons/io5";
import { randomUUId } from "./general";

export function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function getFileExtension(filename) {
    const match = filename.match(/\.(\w+)$/);
    return match ? match[1] : null;
}

export function formatContentItem(item) {
    const isFile = item instanceof File;
    const fileExtension = isFile ? getFileExtension(item.name) : null;

    const removeExtension = (filename) => {
        return filename.split('.').slice(0, -1).join('.') || filename;
    };

    const fileName = isFile ? removeExtension(item.name) : item;

    return {
        id: randomUUId(),
        type: isFile ? 'file' : 'link',
        title: fileName,
        source: {
            url: !isFile ? item : null,
            path: isFile ? URL.createObjectURL(item) || '' : null,
        },
        fileDetails: isFile
            ? {
                size: item.size,
                mimeType: item.type,
                extension: fileExtension,
                lastModified: item.lastModified,
            }
            : null,
        preview: true,
    };
}

export const extIcon = (ext) => {
    let component;

    if (ext === "mp4") {
        component = <AiOutlinePlaySquare color="#fff" />;
    } else if (ext === "link") {
        component = <IoIosLink />;
    } else if (["jpg", "jpeg", "png", "gif", "svg"].includes(ext)) {
        component = <IoMdImage color="#fff" />;
    } else {
        component = <IoDocumentText color="#fff" />;
    }

    return component;
}