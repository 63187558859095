import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { LuImagePlus } from 'react-icons/lu';
import CustomRadioButton from 'components/atoms/CustomRadioButton/CustomRadioButton';
import { Input } from 'components/form';
import { IoCloseCircle } from 'react-icons/io5';
import { randomUUId as randomUUID } from 'helpers/general';
import { answerTypes } from 'store/quiz.slice';

const {
  singleChoice,
  multiChoice,
  textAns,
  pictureChoice
} = answerTypes

const initialOptions = {
  [singleChoice]: [{ text: 'Option 1', isNew: false, id: randomUUID(), index: 0 }],
  [multiChoice]: [
    { text: 'Option 1', isNew: false, id: randomUUID() },
    { text: 'Option 2', isNew: false, id: randomUUID() }
  ],
  Text: [],
  [pictureChoice]: [
    { text: 'Option 1', isNew: false, id: randomUUID() },
    { text: 'Option 2', isNew: false, id: randomUUID() },
    { text: 'Option 3', isNew: false, id: randomUUID() }
  ]
}

const initialAnswers = {
  [singleChoice]: null,
  [multiChoice]: [],
  [pictureChoice]: null,
  [textAns]: null
}

const QuizOptions = ({ selectedType, onChangeOptions, hint, onHintChange, options, onAnswerChange, answer }) => {
  const [imageUrl, setImageUrl] = useState([]);
  const [selectedOptionByType, setSelectedOptionByType] = useState(initialAnswers);

  const [optionsByType, setOptionsByType] = useState(initialOptions);
  const [showHintInput, setShowHintInput] = useState(false);

  useEffect(() => { setShowHintInput(!!hint) }, [hint])

  useEffect(() => {
    if (options.length > 0) {
      setOptionsByType((prevOptions) => ({
        ...prevOptions,
        [selectedType]: options
      }))
    }


  }, [options, selectedType])

  useEffect(() => {
    if (answer) {
      setSelectedOptionByType(prevOptions => {
        return {
          ...prevOptions,
          [selectedType]: answer
        }
      })
    }

  }, [selectedType, answer])

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);

      const updatedOptions = optionsByType[pictureChoice].map((option, i) =>
        i === index ? { ...option, image: url } : option
      );
      onChangeOptions(updatedOptions);

      setImageUrl((prevUrls) => ({ ...prevUrls, [index]: url }));
    }
  };

  const handleAddOption = () => {
    const newOptions = [
      ...optionsByType[selectedType],
      {
        text: `Option ${optionsByType[selectedType].length + 1}`,
        isNew: true,
        id: randomUUID(),
        index: optionsByType[selectedType].length
      }
    ];

    const updatedOptions = { ...optionsByType, [selectedType]: newOptions };
    onChangeOptions(updatedOptions[selectedType]);
  };

  const handleRadioChange = (option) => {
    const updatedSelectedOptionByType = {
      ...selectedOptionByType,
      [selectedType]: option,
    };

    setSelectedOptionByType(updatedSelectedOptionByType);
    onAnswerChange(updatedSelectedOptionByType[selectedType]);
  };

  const handleMultiRadioChange = (option) => {
    const newSelectedOptions = selectedOptionByType[multiChoice];
    const updatedOptions = newSelectedOptions.includes(option)
      ? newSelectedOptions.filter((id) => id !== option)
      : [...newSelectedOptions, option];

    const updatedSelectedOptionByType = {
      ...selectedOptionByType,
      [multiChoice]: updatedOptions,
    };

    setSelectedOptionByType(updatedSelectedOptionByType);
    onAnswerChange(updatedSelectedOptionByType[multiChoice]);
  }

  const handleHintChange = (e) => {
    const hintValue = e.target.value;
    onHintChange(hintValue);
  };

  const handleDeleteOption = (index) => {

    const updatedOptions = optionsByType[selectedType].filter((_, i) => i !== index);
    onChangeOptions(updatedOptions);

    setImageUrl((prevUrls) => {
      const newUrls = { ...prevUrls };
      delete newUrls[index];
      return newUrls;
    });
  };

  const handleOptionTextChange = (index, text) => {

    const updatedOptions = optionsByType[selectedType].map((option, i) =>
      i === index ? { ...option, text } : option
    );
    onChangeOptions(updatedOptions);
  };

  return (
    <div className={styles.container}>
      {selectedType === singleChoice && (
        <div className={styles.optionData}>
          {optionsByType[singleChoice].map((option, index) => {            
            const isChecked = selectedOptionByType[singleChoice] === option.id
            return (
              <div key={index} className={styles.inputGroup}>
                <CustomRadioButton
                  checked={isChecked}
                  onChange={() => handleRadioChange(option.id)}
                  bgChecked="#046799"
                />
                <Input
                  extraClass="quizInput"
                  type="text"
                  placeholder="Click to edit"
                  value={option.text}
                  onChange={(e) => handleOptionTextChange(index, e.target.value)}
                />
                {option.isNew && (
                  <button className={styles.deleteButton} onClick={() => handleDeleteOption(index)}>
                    <IoCloseCircle />
                  </button>
                )}
              </div>
            )
          })}
        </div>
      )}

      {selectedType === multiChoice && (
        <div className={styles.optionData}>
          {optionsByType[multiChoice].map((option, index) => {            
            const isChecked = selectedOptionByType[multiChoice]?.includes(option.id)
            return (
              <div key={index} className={styles.inputGroup}>
                <CustomRadioButton
                  checked={isChecked}
                  onChange={() => handleMultiRadioChange(option.id)}
                  bgChecked="#046799"
                />
                <Input
                  extraClass="quizInput"
                  type="text"
                  placeholder="Click to edit"
                  value={option.text}
                  onChange={(e) => handleOptionTextChange(index, e.target.value)}
                />
                {option.isNew && (
                  <button className={styles.deleteButton} onClick={() => handleDeleteOption(index)}>
                    <IoCloseCircle />
                  </button>
                )}
              </div>
            )
          })}
        </div>
      )}

      {selectedType === textAns && (
        <div className={styles.optionData}>
          <div className={`${styles.inputGroup} ${styles.textareaContainer}`}>
            <span>Answer text:</span>
            <textarea
              placeholder="Click to edit"
              value={selectedOptionByType[textAns]}
              onChange={(e) => handleRadioChange(e.target.value)}
            />
            <p className={styles.limit}>Limit 200 characters</p>
          </div>
        </div>
      )}

      {selectedType === pictureChoice && (
        <div className={`${styles.optionData} ${styles.pictureType}`}>
          {optionsByType[pictureChoice].map((option, index) => {

            const isChecked = selectedOptionByType[pictureChoice] === option.id

            return (
              <div key={index} className={styles.imageContainer}>
                <div
                  className={styles.imageUpload}
                  onClick={() => document.getElementById(`file-input-${index}`).click()}
                  style={{ backgroundImage: `url(${imageUrl[index] || ''})` }}
                >
                  <LuImagePlus />
                  <input
                    id={`file-input-${index}`}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => handleFileChange(event, index)}
                    accept="image/*"
                  />
                </div>
                <div className={styles.radioGroup}>
                  <CustomRadioButton
                    checked={isChecked}
                    onChange={() => handleRadioChange(option.id)}
                    bgChecked="#046799"
                  />
                  <Input
                    type="text"
                    placeholder={option.text}
                    className={styles.editInput}
                    extraClass="pictureInput"
                    value={option.text}
                    onChange={(e) => handleOptionTextChange(index, e.target.value)}
                  />
                  {option.isNew && (
                    <button className={styles.deleteButton} onClick={() => handleDeleteOption(index)}>
                      <IoCloseCircle />
                    </button>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      )}

      {showHintInput && (
        <div className={styles.hintInput}>
          <b>Hint:</b>
          <Input
            type="text"
            value={hint}
            onChange={handleHintChange}
            placeholder="Type hint here"
            extraClass="hintInput"
          />
          <button className={styles.close} onClick={() => setShowHintInput(false)}>
            <IoCloseCircle />
          </button>
        </div>
      )}
      <div className={styles.options}>
        <div className={styles.bottom}>
          {selectedType !== textAns && (
            <button onClick={handleAddOption}>
              <span>+</span> Add option
            </button>
          )}
          {!showHintInput && (
            <button onClick={() => setShowHintInput(true)}>
              <span>+</span> Add hint
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizOptions;
