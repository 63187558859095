import styles from './CourseCard.module.scss';
import Dots from 'assets/icons/dots.svg?react';

export default function CourseCard({ image, title, extraClass, actions, clickEvent, ...rest }) {
  const handleClick = () => {
    if (clickEvent) {
      clickEvent();
    } else {
      console.log('Course Card clicked');
    }
  };

  return (
    <div onClick={handleClick} className={`${styles.card} ${styles[extraClass]}`}>
      <img className={styles.image} src={image} alt="course" />
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        {actions && (
          <div className={styles.dots}>
            <Dots />
          </div>
        )}
        {rest?.by && <h3 className={styles.subtitle}>By {rest?.by}</h3>}
        {rest?.date && <h3 className={styles.subtitle}>{rest?.date}</h3>}
        {rest?.url && (
          <div className={styles.btn}>
            <button className={styles.button}>{rest?.btn ? rest?.btn : 'View course'}</button>
          </div>
        )}
      </div>
    </div>
  );
}
