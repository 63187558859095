import { useState } from 'react';
import styles from './styles.module.scss';

const HintComponent = ({ hint }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={styles.hintContainer}>
            <div className={styles.header} onClick={toggleExpand}>
                <div className={styles.hintTitle}>
                    <div className={styles.icon}>
                        H
                    </div>
                    <span>Hint</span>
                </div>
                <div className={styles.dropdownIcon}>
                    {isExpanded ? '▲' : '▼'}
                </div>
            </div>
            {isExpanded && (
                <div className={styles.hintContent}>
                    {hint || "No hint available"}
                </div>
            )}
        </div>
    );
};


export default HintComponent;
