import styles from './UploadArea.module.scss';

const UploadArea = ({ getRootProps, getInputProps, isDragActive, style }) => (
    <div {...getRootProps()} className={styles.uploadArea} style={style}>
        <input {...getInputProps()} />
        {isDragActive ? (
            <p>Drop the files here...</p>
        ) : (
            <>
                <p>Drag and drop your content <br /> here to start uploading</p>
                <span>-or-</span>
                <button className={styles.browseButton}>Browse files</button>
            </>
        )}
    </div>
);

export default UploadArea;
