import React from 'react';
import styles from './styles.module.scss';
import { Link, useParams } from 'react-router-dom';

export default function NavTabs({ links, customStyles, extraClass }) {
  const { tab } = useParams();
  return (
    <div className={`${styles.nav_links} ${styles[extraClass]}`} style={customStyles}>
      <ul className={styles.nav_links_list}>
        {links?.map((link) => (
          <li key={link.value} className={styles.nav_links_item}>
            <Link
              to={link.path}
              className={`${styles.nav__link} ${
                link?.path?.split('/')?.pop() === tab ? styles.active_link : ''
              }`}
            >
              {link.value}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
