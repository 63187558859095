import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import styles from './Lesson.module.scss'
import { FaPencil } from "react-icons/fa6";
import {  FaPlus, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { addContent, deleteItem, renameItem } from "store/course.slice";
import UploadModal from "components/molecules/UploadModal/UploadModal";
import { formatContentItem } from "helpers/course";
import ContentItem from "../ContentItem/ContentItem";

const Lesson = ({ title, id, content }) => {
    const [isOpen, setIsOpen] = useState(false);    
    const [openUploadModal, setUploadModal] = useState(false);
    const [editable, setEditable] = useState(false)
    const dispatch = useDispatch();

    const handleCloseUploadModal = () => {
        setUploadModal(false)
    }

    const handleOpenUploadModal = () => {
        setUploadModal(true)
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const editLessonName = (e) => {
        e.stopPropagation()
        setEditable(true);
    };

    const handleTitleChange = (e) => {
        const title = e.target.value
        dispatch(renameItem({ itemId: id, itemType: 'lesson', newTitle: title }))
    }

    const deleteLesson = (e) => {
        e.stopPropagation()
        dispatch(deleteItem({ itemId: id, itemType: 'lesson' }))
    };

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            setEditable(false);
        }
    };

    const handleAddContent = (files) => {
        const formattedContent = files.map(file => {
            return formatContentItem(file)
        })

        dispatch(addContent({ lessonId: id, content: formattedContent }))
        setUploadModal(false)
    }

    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'];

    const totalVideoCount = content.filter(item => videoExtensions.includes(item.fileDetails.extension)).length;

    return (
        <div className={styles.container} >
            <div className={styles.top} aria-hidden="true" onClick={toggleDropdown}>
                <div className={styles.title}>
                    <div className={styles.collapse_icon}>
                        {
                            isOpen ? <IoIosArrowUp size={18} /> : <IoIosArrowDown size={18} />
                        }                        
                    </div>
                    {
                        editable ?
                            <input
                                type="text"
                                value={title}
                                onChange={handleTitleChange}
                                onBlur={() => setEditable(false)}
                                onKeyUp={handleKeyUp}
                                autoFocus
                            /> :
                            <div className={styles.section_name}>
                                <div className={styles.section_name_div}>
                                    {title === "" ? "New Lesson" : title}
                                </div>{" "}
                                <div className={styles.icon}>
                                    <FaPencil onClick={editLessonName} />
                                </div>
                            </div>
                    }
                </div>
                <div className={styles.right}>
                    <p>{totalVideoCount} Videos</p>
                    <FaTrash onClick={deleteLesson} />
                </div>
            </div>
            {isOpen && (
                <div className={styles.content}>
                    <div className={styles.data}>
                        {content.map((item) => {
                            return (
                                <ContentItem key={item.id} item={item} />
                            )
                        })}
                        <div
                            onClick={handleOpenUploadModal}
                            aria-hidden="true"
                            className={styles.add_content}
                        >
                            <FaPlus />
                            Add Content
                        </div>
                    </div>
                </div>
            )}
            <UploadModal isOpen={openUploadModal} onClose={handleCloseUploadModal} handleUploadDone={handleAddContent} />
        </div>
    )
}

export default Lesson;
