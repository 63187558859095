import { createSlice } from '@reduxjs/toolkit';

export const previewStages = {
  description: 'description',
  take: 'take',
  complete: 'complete',
  results: 'results',
  summary: 'summary'
};

export const answerTypes = {
  multiChoice: 'Multi choice',
  singleChoice: 'Single choice',
  textAns: 'Text',
  pictureChoice: 'Picture choice'
};

const quizSlice = createSlice({
  name: 'quiz',
  initialState: {
    title: '',
    description: '',
    quizData: [],
    duration: 600,
    timeSpent: 0,
    previewStage: previewStages.description,
    answers: []
  },
  reducers: {
    setQuizData: (state, action) => {
      state.quizData = action.payload.quizData;
      if (action.payload.duration) {
        state.duration = action.payload.duration || null;
      }
    },
    clearQuizData: (state) => {
      state.quizData = [];
      state.duration = null;
    },
    setQuizDuration: (state, action) => {
      state.duration = action.payload;
    },
    setTimeSpent: (state, action) => {
      state.timeSpent = action.payload;
    },
    setQuizTitle: (state, action) => {
      state.title = action.payload;
    },
    setQuizDescription: (state, action) => {
      state.description = action.payload;
    },
    setPreviewStage: (state, action) => {
      state.previewStage = action.payload;
    },
    setQuizAnswers: (state, action) => {
      state.answers = action.payload;
    }
  }
});

export const {
  setQuizData,
  setQuizDuration,
  setTimeSpent,
  clearQuizData,
  setQuizTitle,
  setQuizDescription,
  setPreviewStage,
  setQuizAnswers
} = quizSlice.actions;
export default quizSlice.reducer;
