import SliderContainer from 'components/atoms/SliderContainer/SliderContainer';
import React, { useState } from 'react';
import styles from './Portals.module.scss';
import InfoHubContainerSection from 'components/atoms/InfoHubContainerSection';
import InfoHubTitle from 'components/atoms/InfoHubTitle';
import { AddI, Button, Loader, CloseI, SaveI, EditI } from 'components/ui';
import { Modal } from 'components/modals';
import { FileUploader, Textarea } from 'components/form';
import {
  useCreateCompanyPortalMutation,
  useDeleteCompanyPortalMutation,
  useUpdateCompanyPortalMutation
} from 'api/info-hub';
import { useSelector } from 'react-redux';
import { getCurrentCompanyIdSelector, getIsAdminSelector } from 'store/login.slice';
import { checkIsValidUrl, getS3Url, handleAsync } from 'helpers';
import { useUploadBlob } from 'hooks';
import { showErrorToast } from 'utils/toast';
import Options from 'components/atoms/Options/Options';
import DeleteModal from '../DeleteModal/DeleteModal';
import { useTranslation } from 'react-i18next';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { APP_TIERS } from 'constants';

export default function Portals({ title, portals, colorBgSwitcher }) {
  const [openModal, setOpenModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [portalToBeUpdated, setPortalToBeUpdated] = useState({});
  const [edit, setEdit] = useState(false);
  const isAdmin = useSelector(getIsAdminSelector);
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const [createPortalMutation, { isLoading: isCreateLoading }] = useCreateCompanyPortalMutation();
  const [updatePortalMutation, { isLoading: isUpdateLoading }] = useUpdateCompanyPortalMutation();
  const [deletePortalMutation, { isLoading: isDeleteLoading }] = useDeleteCompanyPortalMutation();
  const { tierData } = useGetUserTierData();
  const userTier = tierData?.tier;
  const [portalData, setPortalData] = useState({
    link: ''
  });
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const { uploadBlob, isUploading } = useUploadBlob();
  const { t } = useTranslation();
  const createPortal = async () => {
    // close model
    setOpenModal(false);
    setPortalData({
      link: ''
    });
    const [status, result] = await handleAsync(uploadBlob(thumbnailFile));
    if (!status) {
      const error = typeof result === 'string' ? result : t('Error uploading thumbnail');
      return showErrorToast(error);
    }
    handleAsync(
      createPortalMutation({
        portal: { ...portalData, thumbnail: result },
        companyId
      }).unwrap()
    );
  };

  const [portalToDeleteId, setPortalToDeleteId] = useState(null);

  const deletePortal = async (id) => {
    const portal = portals.find((item) => item.id === id);
    const thumbnail = portal?.thumbnail;
    handleAsync(deletePortalMutation({ portalId: id, companyId }).unwrap());
    setPortalToDeleteId(null);
    handleClose();
  };
  const handleClose = () => {
    setPortalToDeleteId(null);
  };

  const updatePortal = async () => {
    // close model
    let portal = { ...portalToBeUpdated };
    if (thumbnailFile) {
      const [status, result] = await handleAsync(uploadBlob(thumbnailFile));
      if (!status) {
        const error = typeof result === 'string' ? result : t('Error uploading thumbnail');
        return showErrorToast(error);
      }
      portal.thumbnail = result;
    }
    handleAsync(
      updatePortalMutation({
        portal: { ...portal },
        companyId,
        portalId: portalToBeUpdated.id
      }).unwrap()
    );
    setOpenUpdateModal(false);
    setPortalToBeUpdated({});
  };

  return (
    <div className={`${styles.reports} ${colorBgSwitcher ? styles[`bg-${colorBgSwitcher}`] : ''}`}>
      <InfoHubContainerSection>
        {edit ? (
          <div className={styles.icons}>
            <AddI onClick={() => setOpenModal(true)} classN={styles['addIcon']} />
            <div className={styles.save}>
              <SaveI onClick={() => setEdit(false)} classN={styles['closeIcon']} />
            </div>
          </div>
        ) : (
          <>
            {isAdmin && userTier?.toLowerCase() === APP_TIERS.REGULAR && (
              <Options
                extraClass="videosOptions"
                colorBgSwitcher={colorBgSwitcher}
                handleEdit={() => setEdit(true)}
              />
            )}
          </>
        )}
        <>
          <InfoHubTitle title={title} />
          <SliderContainer
            breakpoint={1440}
            breakpointSlide={3}
            slides={5}
            extraClass="reportsSlider"
          >
            {portals.map((item, i) => {
              return (
                <div className={styles['portal-wrapper']} key={item.id}>
                  {edit && <CloseI onClick={() => setPortalToDeleteId(item.id)} />}
                  {edit && (
                    <EditI
                      onClick={() => {
                        setOpenUpdateModal(true);
                        setPortalToBeUpdated(item);
                      }}
                    />
                  )}
                  <a
                    style={{
                      cursor: 'pointer'
                    }}
                    rel="noreferrer"
                    target="_blank"
                    href={item?.link}
                  >
                    <img
                      alt="thumbnail image"
                      src={item.initial ? item.thumbnail : getS3Url(item.thumbnail)}
                      key={item.id}
                    />
                  </a>
                </div>
              );
            })}
            {(isCreateLoading || isUploading) && <Loader />}
          </SliderContainer>
          <Modal
            isModalOpen={openModal}
            onClose={() => setOpenModal(false)}
            hasHeader
            colorBgSwitcher={colorBgSwitcher}
          >
            <div className={styles['modalContent']}>
              <Textarea
                onChange={({ target: { value } }) => {
                  setPortalData({ ...portalData, link: value });
                }}
                placeholder={t('Portal Link')}
              />
              <FileUploader
                onChange={(files) => {
                  const file = files[0];
                  setThumbnailFile(file);
                }}
                text={t('Upload Portal Thumbnail')}
              />

              <div className={styles['modalButtons']}>
                <Button
                  onClick={() => {
                    const isValidLink = checkIsValidUrl(portalData.link);
                    if (!isValidLink) return showErrorToast(t('Link is should be valid'));
                    if (!thumbnailFile) return showErrorToast(t('Thumbnail is required'));
                    createPortal();
                  }}
                  colorBgSwitcher={colorBgSwitcher}
                >
                  {t('Save')}
                </Button>
                <Button onClick={() => setOpenModal(false)} colorBgSwitcher={colorBgSwitcher}>
                  {t('Close')}
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            isModalOpen={openUpdateModal}
            onClose={() => setOpenUpdateModal(false)}
            hasHeader
            colorBgSwitcher={colorBgSwitcher}
          >
            <div
              style={{
                padding: '1rem'
              }}
            >
              <Textarea
                onChange={({ target: { value } }) => {
                  setPortalToBeUpdated({ ...portalToBeUpdated, link: value });
                }}
                placeholder={t('Portal Link')}
                value={portalToBeUpdated.link}
              />
              <FileUploader
                onChange={(files) => {
                  const file = files[0];
                  setThumbnailFile(file);
                }}
                text={t('Upload Portal Thumbnail')}
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '1rem',
                  gap: '.5rem'
                }}
              >
                <Button
                  onClick={() => {
                    const isValidLink = checkIsValidUrl(portalToBeUpdated.link);
                    if (!isValidLink) return showErrorToast(t('Link is should be valid'));
                    if (!thumbnailFile && !portalToBeUpdated.thumbnail)
                      return showErrorToast(t('Thumbnail is required'));
                    updatePortal();
                  }}
                  colorBgSwitcher={colorBgSwitcher}
                >
                  {t('Save')}
                </Button>
                <Button onClick={() => setOpenUpdateModal(false)} colorBgSwitcher={colorBgSwitcher}>
                  {t('Close')}
                </Button>
              </div>
            </div>
          </Modal>
          {portalToDeleteId !== null && (
            <DeleteModal
              title="are-you-sure-you-want-to-delete"
              handleDelete={() => deletePortal(portalToDeleteId)}
              handleClose={handleClose}
              colorBgSwitcher={colorBgSwitcher}
            />
          )}
        </>
      </InfoHubContainerSection>
    </div>
  );
}

// to check if a string start with http:// or https:// you can use this regex like this:
// const regex = /^(http|https):\/\/[^ "]+$/;
