import Text from 'components/atoms/Text/Text';
import React, { useState } from 'react';
import Icontext from '../IconText/IconText';
import styles from './ClientCasesCard.module.scss';
import PropTypes from 'prop-types';
import { ProfilePic } from 'components/ui';
import Icons from 'components/atoms/Icons/Icons';
import Add from 'assets/icons/addicon.svg?react';
import { EditInput } from 'components/form';
import { deleteFromS3 } from 'helpers/s3Handler';
import { handleAsync } from 'helpers';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import Options from 'components/atoms/Options/Options';
import { Button } from 'components/ui';
import { useNavigate } from 'react-router-dom';
import { SaveI, CloseI } from 'components/ui';
function ClientCasesCard({
  id,
  image,
  profile,
  title,
  paragraph,
  location,
  extraClass,
  heading,
  subheading,
  client,
  edit,
  create,
  cancelCreate,
  hideDetails,
  viewDetailsRedirection,
  social,
  createClient,
  updateClient,
  deleteClient,
  uploadBlob,
  ...rest
}) {
  const EPictureType = {
    profile: 'profile',
    header: 'header'
  };
  const [InnerEdit, setInnerEdit] = useState(false);
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    cardName: title,
    cardSubtext: paragraph,
    cardLocation: location,
    headerText: heading,
    bodyText: subheading,
    companyId: rest.companyId
  });
  const inputFileRef = React.useRef(null);
  const handleFileAddition = (file, pictureType) => {
    const objectUrl = URL.createObjectURL(file);
    if (file.type.includes('image/')) {
      if (pictureType === EPictureType.profile) {
        setFields({
          ...fields,
          profilePicture: objectUrl,
          profilePictureFile: file,
          profilePictureChanged: true
        });
      }
      if (pictureType === EPictureType.header) {
        setFields({
          ...fields,
          headerImage: objectUrl,
          headerPictureFile: file,
          headerPictureChanged: true
        });
      }
    }
    // reset the input file
    inputFileRef.current.value = '';
  };
  async function handleUpload(file) {
    // return uploadBlob(file, 'InfoHubContents').then((response) => response);
    const [flag, response] = await handleAsync(uploadBlob(file, 'InfoHubContents'));
    if (!flag) {
      const errorType = typeof response === 'string' ? response : 'Some files failed to upload';
      showErrorToast(errorType);
      return null;
    }
    return response;
  }
  async function cleanUploads(link) {
    await handleAsync(deleteFromS3(link));
  }
  async function attemptToSaveClient(upload) {
    //save experience
    if (client) {
      let uploadedHeader;
      let uploadProfile;
      try {
        if (fields.headerImage) {
          uploadedHeader = await handleUpload(fields.headerPictureFile);
          if (!uploadedHeader) return;
        }
        if (fields.profilePicture) {
          uploadProfile = await handleUpload(fields.profilePictureFile);
          if (!uploadProfile) return;
        }
      } catch (e) {
        if (uploadedHeader) {
          cleanUploads(uploadedHeader);
        }
        if (uploadProfile) {
          cleanUploads(uploadProfile);
        }
        return;
      }
      if (upload) {
        if (!fields.headerImage) {
          uploadedHeader = image;
        }
        if (!fields.profilePicture) {
          uploadProfile = profile;
        }
        const [status, result] = await handleAsync(
          updateClient({
            clientId: id,
            client: {
              headerImage: uploadedHeader,
              profilePicture: uploadProfile,
              clientName: fields.cardName,
              clientSubtext: fields.cardSubtext,
              clientLocation: fields.cardLocation,
              headerText: fields.headerText,
              bodyText: fields.bodyText,
              companyId: rest.companyId
            }
          }).unwrap()
        );

        if (result.status === 400)
          return showErrorToast('Please fill in all the inputs before submitting');

        if (result.status >= 500 || !status) return showErrorToast('Something went wrong');

        setInnerEdit(false);
        showSuccessToast('Client case updated!');
      } else {
        const [status, result] = await handleAsync(
          createClient({
            client: {
              headerImage: uploadedHeader,
              profilePicture: uploadProfile,
              clientName: fields.cardName,
              clientSubtext: fields.cardSubtext,
              clientLocation: fields.cardLocation,
              headerText: fields.headerText,
              bodyText: fields.bodyText,
              companyId: rest.companyId
            }
          }).unwrap()
        );
        if (result.status === 400)
          return showErrorToast('Please fill in all the inputs before submitting');

        if (result.status >= 500 || !status) return showErrorToast('Something went wrong');
        setFields({
          cardName: '',
          cardSubtext: '',
          cardLocation: '',
          headerText: '',
          bodyText: '',
          companyId: rest.companyId
        });
        showSuccessToast('Client case created!');
      }
    }
  }
  return (
    <>
      <div className={`${styles.infoHubCard} ${styles[extraClass]}`}>
        {rest?.options && (
          <Options
            extraClass="infoOptions"
            handleEdit={() => {
              setInnerEdit(true);
            }}
            handleSave={async () => {
              //create
              if (create) {
                attemptToSaveClient();
              } else {
                attemptToSaveClient(true);
              }
            }}
            handleDiscard={() => {
              setInnerEdit(false);
            }}
            edit={InnerEdit}
            handleDelete={() => deleteClient({ clientId: id })}
          />
        )}
        {!InnerEdit && !create && (
          <div className={styles.imagepart} style={{ backgroundImage: `url(${image})` }}>
            <ProfilePic
              extraClass="infoProfile"
              image={fields.profilePicture ? fields.profilePicture : profile}
              width={75}
              height={75}
            />
          </div>
        )}
        {(create || InnerEdit) && (
          <div className={styles.imageInputs}>
            <div
              className={styles.topInput}
              style={{ backgroundImage: `url(${fields.headerImage ? fields.headerImage : image})` }}
            >
              <input
                style={{ display: 'none' }}
                type="file"
                ref={inputFileRef}
                accept="image/*"
                id={`upload${rest.id}topInput`}
                multiple={false}
                onChange={(e) => handleFileAddition(e.target.files[0], EPictureType.header)}
              />
              <label htmlFor={`upload${rest.id}topInput`}>
                <Add />
              </label>
            </div>
            <div className={styles.profileInputContainer}>
              <div
                className={styles.profileInput}
                style={{
                  backgroundImage: `url(${fields.profilePicture ? fields.profilePicture : profile})`
                }}
              >
                <input
                  style={{ display: 'none' }}
                  type="file"
                  ref={inputFileRef}
                  accept="image/*"
                  id={`upload${rest.id}ProfilePicture`}
                  multiple={false}
                  onChange={(e) => handleFileAddition(e.target.files[0], EPictureType.profile)}
                />
                <label htmlFor={`upload${rest.id}ProfilePicture`}>
                  <Add />
                </label>
              </div>
            </div>
          </div>
        )}
        <div className={styles.container}>
          {!InnerEdit && !create && (
            <div className={styles.content}>
              <Text extraClass="clientName" {...{ title, paragraph }} />
              <Icontext text={location} />
              {!client && <Icons extraClass="infoIcons" social={social} />}
            </div>
          )}
          {(create || InnerEdit) && (
            <div className={styles.content}>
              <EditInput
                placeholder={'Title'}
                value={fields.cardName}
                onChange={({ target }) => setFields({ ...fields, cardName: target.value })}
                extraClass={styles.editFieldClientCard}
              />
              <EditInput
                placeholder={'Paragraph'}
                value={fields.cardSubtext}
                onChange={({ target }) => setFields({ ...fields, cardSubtext: target.value })}
                extraClass={styles.editFieldClientCard}
              />
              <EditInput
                placeholder={'Location'}
                value={fields.cardLocation}
                onChange={({ target }) => setFields({ ...fields, cardLocation: target.value })}
                extraClass={styles.editFieldClientCard}
              />
            </div>
          )}
          {create ||
            (!InnerEdit && (
              <div className={styles.detailsBtn}>
                <Text extraClass="details" title={heading} paragraph={subheading} />
                {!hideDetails && (
                  <Button
                    bgColor="#03030347"
                    color="white"
                    onClick={() => {
                      navigate(`/${viewDetailsRedirection}`);
                    }}
                  >
                    View Details
                  </Button>
                )}
              </div>
            ))}
          {(create || InnerEdit) && (
            <div className={styles.editInputs}>
              <EditInput
                placeholder={'Heading'}
                value={fields.headerText}
                onChange={({ target }) => setFields({ ...fields, headerText: target.value })}
                extraClass={styles.editFieldClientCard}
              />
              <EditInput
                placeholder={'SubHeading'}
                value={fields.bodyText}
                onChange={({ target }) => setFields({ ...fields, bodyText: target.value })}
                extraClass={styles.editFieldClientCard}
              />
            </div>
          )}
        </div>
        {create && (
          <div className={styles.saveDiscard}>
            {
              <SaveI
                onClick={() => {
                  attemptToSaveClient(false);
                }}
              />
            }
            {
              <CloseI
                onClick={() => {
                  cancelCreate();
                }}
              />
            }
          </div>
        )}
      </div>
    </>
  );
}
export default ClientCasesCard;
ClientCasesCard.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  paragrapgh: PropTypes.string,
  location: PropTypes.string,
  profile: PropTypes.string,
  extraClass: PropTypes.string
};
