import React, { useState } from 'react';
// remove the style from here
import styles from './AvailabilityTab.module.scss';
import Select from 'react-select';
import { motion } from 'framer-motion';
import { useUpdateAvailabilityMutation } from 'api/individual-profile';
import { handleAsync, isSharedMode } from 'helpers';
import { getDefaultTimezone } from 'helpers/dateHandler';
import { showWarnToast } from 'utils/toast';
import { Button } from 'components/ui';
import { AvailabilityTable } from './sections';
import { TZ_STRING } from 'constants';
import { DEFAULT_SELECTED_TIMES } from 'constants';
import { useTranslation } from 'react-i18next';
import EditI from 'assets/icons/edit.svg?react';
import { getGlobalEditSelector, setGlobalEdit } from 'store/individualProfile.slice';
import { useDispatch, useSelector } from 'react-redux';

export default function Availability({ profileId, isCurrentUserProfile, data }) {
  const [availability, setAvailability] = useState({
    ...data,
    daysAvailability: data?.daysAvailability || DEFAULT_SELECTED_TIMES
  });
  const dispatch = useDispatch();
  const globalEdit = useSelector(getGlobalEditSelector);

  const { t } = useTranslation();
  const [updateAvailability, { isLoading }] = useUpdateAvailabilityMutation();

  const getProfileTimezoneObject = (value) => {
    const timezone = value;
    const timezoneObject = TZ_STRING.find((item) => item.value === timezone);
    return timezoneObject;
  };

  const handleSubmit = async () => {
    if (!availability?.timezone) {
      showWarnToast(t('Please select timezone'));
      return;
    }
    handleAsync(
      updateAvailability({ profileId, availability }).unwrap(),
      t('Availability updated successfully'),
      t('error-updating-availability')
    );
  };

  let content;
  const userTimeZone = getDefaultTimezone();
  let defaultTimezoneObject = userTimeZone
    ? { value: userTimeZone.split(' ')[0], label: userTimeZone }
    : undefined;
  content = (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <div className={styles.time}>
        {isCurrentUserProfile && !isSharedMode() && (
          <span
            onClick={() => {
              dispatch(setGlobalEdit(!globalEdit));
            }}
          >
            <div className={`${styles.globalEdit} ${globalEdit ? styles.clicked : ''}`}>
              {' '}
              <EditI />{' '}
            </div>
          </span>
        )}
        <h3 className={styles.title}>{t('Time Zone')}</h3>
        <p>{availability?.timezone || t('Not specified')}</p>
        {isCurrentUserProfile && (
          <Select
            key={availability?.timezone}
            className={styles['time-zone-selector']}
            classNamePrefix="select"
            defaultValue={getProfileTimezoneObject(availability.timezone)}
            isSearchable={true}
            name="color"
            options={defaultTimezoneObject ? [defaultTimezoneObject, ...TZ_STRING] : TZ_STRING}
            onChange={(timezone) => {
              setAvailability({ ...availability, timezone: timezone.value });
            }}
          />
        )}
      </div>
      <AvailabilityTable
        isTimezoneSelected={!!availability?.timezone}
        originalDaysAvailability={data?.daysAvailability}
        daysAvailability={availability?.daysAvailability}
        handleChange={(values) => {
          setAvailability({ ...availability, daysAvailability: values });
        }}
      />
      {isCurrentUserProfile && (
        <div
          style={{
            textAlign: 'right',
            margin: '10px 0px'
          }}
        >
          <Button
            style={{
              marginLeft: 'auto'
            }}
            color="white"
            bgColor="#343434"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? t('loading') : t('Submit')}
          </Button>
        </div>
      )}
    </motion.div>
  );

  return content;
}
