import React, { useState } from 'react';
import styles from './styles.module.scss';
import data from './data_dummy';
import TableHeaders from './TableHeaders';
import TableBody from './TableBody';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function index() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [sorter, setSorter] = useState({
    type: 'name',
    asc: true
  });

  const CONTENT_COUNT = 7;

  const pageParams = searchParams?.get('page') || 1;
  const totalElements = data?.length;
  const totalPages = Math.ceil(data?.length / CONTENT_COUNT);
  const hasNextPage = pageParams < totalPages;
  const localPageDetails = {
    page: {
      totalElements,
      totalPages,
      number: Number(pageParams) || 1,
      hasNextPage
    }
  };

  const localNavToPage = (pageN) => {
    if (pageParams) {
      searchParams?.set('page', pageN);
    } else {
      searchParams?.append('page', pageN);
    }
    navigate(location.pathname + '?' + searchParams?.toString()?.replace(/%2C/g, ','));
  };

  const sortedData = [...data].sort((a, b) => {
    const { type, asc } = sorter;
    let sortOrder = asc ? 1 : -1;

    if (type === 'name') {
      return a.name.localeCompare(b.name) * sortOrder;
    } else if (type === 'spent_time' || type === 'progress') {
      return (a[type] - b[type]) * -1 * sortOrder;
    } else if (type === 'purchase_date' || type === 'expiry_date') {
      return (new Date(a[type]) - new Date(b[type])) * -1 * sortOrder;
    } else if (type === 'certified') {
      if (a.progress === b.progress) {
        return a.certified === b.certified ? 0 : a.certified ? -1 : 1;
      }
      return (b.progress - a.progress) * sortOrder;
    }
    return 0;
  });

  const { page } = localPageDetails;

  const paginatedData = sortedData.slice(
    (page.number - 1) * CONTENT_COUNT,
    page.number * CONTENT_COUNT
  );

  const arrayOfPageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1) || [];

  const handleCertification = (student) => {
    const { certified } = student || {};
    if (certified) {
      // show cert popup
    } else {
      // show give cert popup
    }
  };

  return (
    <div className={styles.container}>
      <table className={styles.part_table} cellSpacing="0">
        {/* <caption className={styles.table_header}>Statement Summary</caption> */}
        <thead className={styles.table_thead}>
          <TableHeaders sorter={sorter} setSorter={setSorter} />
        </thead>
        <tbody className={styles.table_tbody}>
          {paginatedData?.map((entry) => (
            <TableBody key={entry?._id} entry={entry} handleCertification={handleCertification} />
          ))}
        </tbody>
      </table>
      <div className={styles.bottom_control_section}>
        <ul className={styles.pagination_container}>
          {arrayOfPageNumbers.map((pageNumber) => (
            <li
              key={pageNumber}
              className={`${styles.page_number} ${pageNumber === page.number ? styles.active : ''}`}
            >
              <button className={styles.page_btn} onClick={() => localNavToPage(pageNumber)}>
                {pageNumber}
              </button>
            </li>
          ))}
        </ul>
        <div className={styles.export_btns}>{/* */}</div>
      </div>
    </div>
  );
}
