export const MW_MODULES_MAPPERS = {
  library: 'library',
  studio: 'studio',
  'wizr x': 'wizrx',
  'wizr connect': 'videodashboard',
  mpublisher: 'mpublisher',
  'wizr space': 'wizrspaces',
  'wizr up': 'wizrup'
};

export const imagesTypes = ['png', 'jpg', 'jpeg', 'gif', 'svg'];
export const videoTypes = ['mp4', 'mov', 'avi', 'mkv'];
export const audioTypes = ['mp3', 'wav', 'aac', 'ogg'];
export const pdfTypes = ['pdf'];
export const docTypes = ['doc', 'docx'];
export const excelTypes = ['xls', 'xlsx'];
export const pptTypes = ['ppt', 'pptx'];
export const zipTypes = ['zip', 'rar'];

export const MEDIA_TYPES = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  PDF: 'PDF',
  DOC: 'DOC',
  EXCEL: 'EXCEL',
  PPT: 'PPT',
  ZIP: 'ZIP'
};

export const ROUTES_PATHS = {
  home: () => ({
    value: '/',
    dynamicPath: `/`
  }),
  guides: () => ({
    value: '/guides',
    dynamicPath: `/guides`
  }),
  allProfiles: () => ({
    value: '/profiles',
    dynamicPath: `/profiles`
  }),
  profile: () => ({
    value: '/profile',
    dynamicPath: `/profile`
  }),
  userProfile: (id) => ({
    value: `/profile/:id`,
    dynamicPath: `/profile/${id}`
  }),
  allTeams: () => ({
    value: '/teams',
    dynamicPath: `/teams`
  }),
  teamProfile: (id) => ({
    value: `/team/:id`,
    dynamicPath: `/team/${id}`
  }),
  calendar: () => ({
    value: '/calendar',
    dynamicPath: `/calendar`
  }),
  singleEvent: (id) => ({
    value: `/calendar/event/:id`,
    dynamicPath: `/calendar/event/${id}`
  }),
  edit: (id) => ({
    value: `/edit/:id`,
    dynamicPath: `/edit/${id}`
  }),
  socialBoard: () => ({
    value: '/news-hub',
    dynamicPath: `/news-hub`
  }),
  templates: () => ({
    value: '/templates',
    dynamicPath: `/templates`
  }),
  newshubPost: () => ({
    value: '/sharedPost',
    dynamicPath: `/sharedPost`
  }),
  workingHours: () => ({
    value: '/working-hours',
    dynamicPath: `/working-hours`
  }),
  chat: () => ({
    value: '/chat',
    dynamicPath: `/chat`
  }),
  infoHub: () => ({
    value: '/info-hub',
    dynamicPath: `/info-hub`
  }),
  info: () => ({
    value: '/info',
    dynamicPath: `/info`
  }),
  // socialBoard: () => ({
  //   value: '/social-boards',
  //   dynamicPath: `/social-boards`
  // }),
  userCompany: (id) => ({
    value: `/company/:id`,
    dynamicPath: `/company/${id}`
  }),
  company: () => ({
    value: '/company',
    dynamicPath: `/company`
  }),
  companySetting: () => ({
    value: '/settings',
    dynamicPath: `/settings`
  }),
  explore: () => ({
    value: '/explore',
    dynamicPath: `/explore`
  }),
  myDashboard: () => ({
    value: '/dashboard/*',
    dynamicPath: `/dashboard/*`
  }),
  courses: () => ({
    value: '/courses',
    dynamicPath: `/courses`
  }),
  newCourse: () => ({
    value: '/dashboard/courses/new',
    dynamicPath: `/dashboard/courses/new`
  }),
  quiz: () => ({
    value: '/quiz/*',
    dynamicPath: `/quiz/*`
  }),
  quizPreview: () => ({
    value: '/quiz/preview',
    dynamicPath: `/quiz/preview`
  }),
  course: () => ({
    value: 'course/*',
    dynamicPath: `course/*`
  })
};

export const APP_TIERS = {
  REGULAR: 'regular',
  LITE: 'lite'
};

export const INITIAL_PORTALS = [
  {
    id: '',
    link: '',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/A4_-_20_2_o3wfol.webp',
    initial: true
  },
  {
    id: '',
    link: '',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/A4_-_22_2_yeczki.webp',
    initial: true
  },
  {
    id: '',
    link: '',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/A4_-_13_2_pxks4l.webp',
    initial: true
  },
  {
    id: '',
    link: '',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/A4_-_21_2_dsgb4s.webp',
    initial: true
  },
  {
    id: '',
    link: '',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/A4_-_16_2_bpsvuo.webp',
    initial: true
  }
];
export const INITIAL_REPORTS = [
  {
    id: '',
    link: '',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/A4_-_15_2_qx5lmh.webp',
    initial: true
  },
  {
    id: '',
    link: '',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/A4_-_14_2_fdbbbg.webp',
    initial: true
  },
  {
    id: '',
    link: '',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/A4_-_19_2_wujno1.webp',
    initial: true
  },
  {
    id: '',
    link: '',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/A4_-_17_2_tpjarf.webp',
    initial: true
  },
  {
    id: '',
    link: '',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/A4_-_18_2_nkat4r.webp',
    initial: true
  }
];
