import { useDispatch, useSelector } from 'react-redux';
import styles from './CourseStructure.module.scss';
import { FaPlus } from 'react-icons/fa';
import { addChapter } from 'store/course.slice';
import Chapter from '../Chapter/Chapter';
import { randomUUId } from 'helpers/general';

const new_chapter_obj = {
    id: null,
    action: "",
    title: "New Chapter",
    duration: 0,
    lessons: [],
    is_deleted: false,
};

export default function CourseStructure () {
    const { course: { chapters } } = useSelector(state => state.course);
    const dispatch = useDispatch()    

    const handleAddChapter = () => {
        dispatch(addChapter({...new_chapter_obj, id: randomUUId()}));
    }

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <h2>Course Structure</h2>
                <p>
                    {chapters.length} Chapters
                </p>
                <div className={styles.data}>
                    {chapters.map((chapter, index) => {
                        return (
                            <Chapter key={chapter.id} {...chapter} />
                        )
                    })}
                    <div
                        aria-hidden="true"
                        className={styles.add_lesson}
                        onClick={handleAddChapter}
                        style={{ cursor: "pointer" }}
                    >
                        <FaPlus /> Add Chapter
                    </div>
                </div>
            </div>
        </div>
    )
}
