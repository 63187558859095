import SliderContainer from 'components/atoms/SliderContainer/SliderContainer';
import React, { useState } from 'react';
import styles from './InfohubDocuments.module.scss';
import InfoHubContainerSection from 'components/atoms/InfoHubContainerSection';
import InfoHubTitle from 'components/atoms/InfoHubTitle';
import { AddI, Button, Loader, CloseI, SaveI, EditI } from 'components/ui';
import Options from 'components/atoms/Options/Options';
import { Modal } from 'components/modals';
import { FileUploader, Textarea } from 'components/form';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { checkIsValidUrl, getS3Url, handleAsync } from 'helpers';
import { useUploadBlob } from 'hooks';
import { useSelector } from 'react-redux';
import { getCurrentCompanyIdSelector, getIsAdminSelector } from 'store/login.slice';
import DeleteModal from '../DeleteModal/DeleteModal';
import {
  useCreateCompanyDocumentMutation,
  useDeleteCompanyDocumentMutation,
  useUpdateCompanyDocumentMutation
} from 'api/info-hub';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { APP_TIERS } from 'constants';

export default function InfohubDocuments({ title, documents, colorBgSwitcher }) {
  const [openModal, setOpenModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [documentToBeUpdated, setDocumentToBeUpdated] = useState({});
  const [edit, setEdit] = useState(false);
  const { tierData } = useGetUserTierData();
  const userTier = tierData?.tier;
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const [createDocumentMutation, { isLoading: isCreateLoading }] =
    useCreateCompanyDocumentMutation();
  const [updateDocumentMutation, { isLoading: isUpdateLoading }] =
    useUpdateCompanyDocumentMutation();
  const [deleteDocumentMutation, { isLoading: isDeleteLoading }] =
    useDeleteCompanyDocumentMutation();
  const isAdmin = useSelector(getIsAdminSelector);
  // the query won't run since it's already cached

  const [documentData, setDocumentData] = useState({
    link: ''
  });
  const { uploadBlob, isUploading } = useUploadBlob();
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const createDocument = async () => {
    // close model
    setOpenModal(false);
    setDocumentData({
      link: ''
    });
    const [status, result] = await handleAsync(uploadBlob(thumbnailFile));
    if (!status) {
      const error = typeof result === 'string' ? result : 'Error uploading thumbnail';
      return showErrorToast(error);
    }
    const [apiStatus] = await handleAsync(
      createDocumentMutation({
        document: { ...documentData, thumbnail: result },
        companyId
      }).unwrap()
    );
    setThumbnailFile(null);
    setDocumentData({});
    if (!apiStatus) return showErrorToast('Something went wrong');
  };

  const updateDocument = async () => {
    // close model
    let document = { ...documentToBeUpdated };
    if (thumbnailFile) {
      const [status, result] = await handleAsync(uploadBlob(thumbnailFile));
      if (!status) {
        const error = typeof result === 'string' ? result : 'Error uploading thumbnail';
        return showErrorToast(error);
      }
      document.thumbnail = result;
    }
    const [apiStatus, result] = await handleAsync(
      updateDocumentMutation({
        document: { ...document },
        companyId,
        documentId: documentToBeUpdated.id
      }).unwrap()
    );
    if (!apiStatus) return showErrorToast('Something went wrong');
    setOpenUpdateModal(false);
    setDocumentToBeUpdated({});
    setThumbnailFile(null);
  };

  const [documentToDeleteId, setDocumentToDeleteId] = useState(null);

  const deleteDocument = async (id) => {
    const document = documents.find((item) => item.id === id);
    const [status] = await handleAsync(
      deleteDocumentMutation({
        documentId: id,
        companyId,
        document: { link: document.link, thumbnail: document.thumbnail }
      }).unwrap()
    );
    if (!status) return showErrorToast('Something went wrong');
    showSuccessToast('Document Deleted ');
    setDocumentToDeleteId(null);
    handleClose();
  };
  const handleClose = () => {
    setDocumentToDeleteId(null);
  };

  // the query won't run since it's already cached
  return (
    <div
      className={`${styles.documents} ${colorBgSwitcher ? styles[`bg-${colorBgSwitcher}`] : ''}`}
    >
      <InfoHubContainerSection>
        {edit ? (
          <div className={styles.icons}>
            <AddI onClick={() => setOpenModal(true)} classN={styles['addIcon']} />
            <div className={styles.save}>
              <SaveI onClick={() => setEdit(false)} classN={styles['closeIcon']} />
            </div>
          </div>
        ) : (
          <>
            {isAdmin && userTier?.toLowerCase() === APP_TIERS.REGULAR && (
              <Options extraClass="videosOptions" handleEdit={() => setEdit(true)} />
            )}
          </>
        )}
        <>
          <InfoHubTitle title={title} />
          <SliderContainer
            breakpoint={1440}
            breakpointSlide={3}
            slides={5}
            extraClass="documentsSlider"
          >
            {documents?.map((item, i) => (
              <div className={styles['portal-wrapper']} key={item.id}>
                {edit && <CloseI onClick={() => setDocumentToDeleteId(item.id)} />}
                {edit && (
                  <EditI
                    onClick={() => {
                      setOpenUpdateModal(true);
                      setDocumentToBeUpdated(item);
                    }}
                  />
                )}
                <a
                  style={{
                    cursor: 'pointer'
                  }}
                  rel="noreferrer"
                  target="_blank"
                  href={item?.link}
                >
                  <img
                    alt="thumbnail image"
                    src={item.initial ? item.thumbnail : getS3Url(item.thumbnail)}
                    key={item.id}
                  />
                </a>
              </div>
            ))}
            {(isCreateLoading || isUploading) && <Loader />}
          </SliderContainer>
          <Modal
            isModalOpen={openModal}
            onClose={() => setOpenModal(false)}
            hasHeader
            colorBgSwitcher={colorBgSwitcher}
          >
            <div
              style={{
                padding: '1rem'
              }}
            >
              <Textarea
                onChange={({ target: { value } }) => {
                  setDocumentData({ ...documentData, link: value });
                }}
                placeholder="Document Link"
              />
              <FileUploader
                onChange={(files) => {
                  const file = files[0];
                  setThumbnailFile(file);
                }}
                text="Upload Document Thumbnail"
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '1rem',
                  gap: '.5rem'
                }}
              >
                <Button
                  onClick={() => {
                    const isValidLink = checkIsValidUrl(documentData.link);
                    if (!isValidLink) return showErrorToast('Link is should be valid');
                    if (!thumbnailFile) return showErrorToast('Thumbnail is required');
                    createDocument();
                  }}
                  colorBgSwitcher={colorBgSwitcher}
                >
                  Save
                </Button>
                <Button onClick={() => setOpenModal(false)} colorBgSwitcher={colorBgSwitcher}>
                  Close
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            isModalOpen={openUpdateModal}
            onClose={() => setOpenUpdateModal(false)}
            hasHeader
            colorBgSwitcher={colorBgSwitcher}
          >
            <div
              style={{
                padding: '1rem'
              }}
            >
              <Textarea
                onChange={({ target: { value } }) => {
                  setDocumentToBeUpdated({ ...documentToBeUpdated, link: value });
                }}
                placeholder="Document Link"
                value={documentToBeUpdated.link}
              />
              <FileUploader
                onChange={(files) => {
                  const file = files[0];
                  setThumbnailFile(file);
                }}
                text="Upload Document Thumbnail"
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '1rem',
                  gap: '.5rem'
                }}
              >
                <Button
                  onClick={() => {
                    const isValidLink = checkIsValidUrl(documentToBeUpdated.link);
                    if (!isValidLink) return showErrorToast('Link is should be valid');
                    if (!thumbnailFile && !documentToBeUpdated.thumbnail)
                      return showErrorToast('Thumbnail is required');
                    updateDocument();
                  }}
                  colorBgSwitcher={colorBgSwitcher}
                >
                  Save
                </Button>
                <Button onClick={() => setOpenUpdateModal(false)} colorBgSwitcher={colorBgSwitcher}>
                  Close
                </Button>
              </div>
            </div>
          </Modal>
          {documentToDeleteId !== null && (
            <DeleteModal
              title="Are you sure you want to delete this Document?"
              handleDelete={() => deleteDocument(documentToDeleteId)}
              handleClose={handleClose}
              colorBgSwitcher={colorBgSwitcher}
            />
          )}
        </>
      </InfoHubContainerSection>
    </div>
  );
}
