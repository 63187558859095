import React, { useState, useRef, useEffect } from 'react';
import EmojiPicker from 'emoji-picker-react';
import style from '../../../styles.module.scss';
import styles from './styles.module.scss';
import profile from 'assets/images/portalofmonth.webp';
import Emoji from 'assets/icons/emoji.svg?react';
import { Button } from 'components/ui';

export default function Index() {
  const [userInput, setUserInput] = useState('');
  const [comments, setComments] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSave = () => {
    if (userInput.trim()) {
      const newComment = {
        id: comments.length + 1,
        text: userInput
      };
      setComments([...comments, newComment]);
      setUserInput('');
    }
  };

  const onEmojiClick = (emojiData) => {
    setUserInput((prevInput) => prevInput + emojiData.emoji);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <h1 className={style.sectionTitle}>Q&A ({comments.length})</h1>
      <div className={styles.container}>
        <div className={`${styles.upper_panel} courseComment`}>
          <textarea
            placeholder="Type message here"
            onChange={handleInputChange}
            value={userInput}
          ></textarea>
          <button
            type="button"
            className={styles.emojiBtn}
            onClick={() => setShowEmojiPicker((prev) => !prev)}
          >
            <Emoji />
          </button>
          {showEmojiPicker && (
            <div ref={emojiPickerRef}>
              <EmojiPicker onEmojiClick={onEmojiClick} />
            </div>
          )}
        </div>
        <div className={styles.lower_panel}>
          <Button
            bgColor={userInput.trim() ? '#046799' : '#37363687'}
            onClick={handleSave}
            disabled={!userInput.trim()}
          >
            Post
          </Button>
        </div>
        <div className={styles.comments_list}>
          {comments.map((comment) => (
            <div className={styles.commentBox} key={comment.id}>
              <img src={profile} alt="profile" />
              <div className={styles.commentWrapper}>
                <div className={styles.nameHolder}>
                  <p>Alisa</p>
                  <span>● Just now</span>
                </div>
                <h1 className={styles.comment}>{comment.text}</h1>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
