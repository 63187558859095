import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './NewCourse.module.scss';
import { Button } from 'components/ui';
import Arrow from 'assets/icons/arrowright.svg?react';
import { FaImage } from 'react-icons/fa6';
import CourseStructure from 'components/organisms/UpDashboard/components/CreateCourse/CourseStructure/CourseStructure';
import AboutCourse from '../UpDashboard/components/CreateCourse/AboutCourse/AboutCourse';

const GoBackButton = ({ t }) => {
  const navigate = useNavigate();
  const goToPreviousPage = () => navigate(-1);
  return (
    <div className={styles.goBack}>
      <Button bgColor="transparent" extraClass="backButton" onClick={goToPreviousPage}>
        <Arrow />
        {t('Go Back')}
      </Button>
    </div>
  );
};

const HeaderContent = () => (
  <div className={styles.headerContent}>
    <div className={styles.tileForm}>
      <label htmlFor="title">Course Title</label>
      <input type="text" id="title" />
      <label htmlFor="subtitle">Subtitle</label>
      <input type="text" id="subtitle" />
    </div>
  </div>
);

const NewCourse = () => {
  const [backgroundImage, setBackgroundImage] = useState(
    "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url('https://cdn.wizrx.wizrapps.com/Frame_18_2_xvdh6b.webp')"
  );
  const fileInputRef = useRef(null);
  const { t } = useTranslation();

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackgroundImage(
          `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url(${reader.result})`
        );
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileUpload = () => {
    fileInputRef.current.click();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerSection} style={{ backgroundImage }}>
        <GoBackButton t={t} />
        <HeaderContent />
        <FaImage className={styles.uploadBannerIcon} onClick={triggerFileUpload} />
        <input
          type="file"
          ref={fileInputRef}
          className={styles.hidden}
          accept="image/*"
          onChange={handleImageUpload}
        />
      </div>

      <div className={styles.aboutCourse}>
        <AboutCourse />
      </div>

      <CourseStructure />
    </div>
  );
};

export default NewCourse;
