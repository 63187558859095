import React from 'react';
import styles from './CustomRadioButton.module.scss';
import PropTypes from 'prop-types';

export default function CustomRadioButton({ checked, onChange, bgChecked, extraClass }) {
  return (
    <div
      className={`${styles.radioButton} ${checked ? styles.checked : ''} ${styles[extraClass]}`}
      onClick={onChange}
    >
      <div className={styles.innerCircle} style={{ background: checked && bgChecked }}></div>
    </div>
  );
}

CustomRadioButton.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  bgChecked: PropTypes.string
};
