import SkillList from '../SkillList/SkillList';
import SkillsInput from '../SkillsInput/SkillsInput';
import styles from './CourseDetails.module.scss';

const CourseDetails = ({
    skills,
    skillInput,
    showSuggestions,
    filteredSuggestions,
    suggestionRef,
    onInputChange,
    onKeyPress,
    onAddSkill,
    onRemoveSkill
}) => (
    <div className={styles.details}>
        <div className={styles.courseDesc}>
            <label>About this course</label>
            <textarea placeholder="Describe the course..." />
        </div>
        <SkillsInput
            skillInput={skillInput}
            showSuggestions={showSuggestions}
            filteredSuggestions={filteredSuggestions}
            suggestionRef={suggestionRef}
            onInputChange={onInputChange}
            onKeyPress={onKeyPress}
            onAddSkill={onAddSkill}
        />
        <SkillList skills={skills} onRemoveSkill={onRemoveSkill} />
    </div>
);

export default CourseDetails;
