import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { CustomDropdown } from 'components/form';
import CustomRadioButton from 'components/atoms/CustomRadioButton/CustomRadioButton';
import { COUNTRIES_BY_CONTINENTS } from 'constants';
import Toggle from 'components/atoms/Toggle/Toggle';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import 'components/molecules/QuizOptions/timepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { setQuizDuration } from 'store/quiz.slice';

const QuizSettings = () => {  
  const options = [
    { label: 'Immediately', desc: 'The quiz is made available on course enrollment' },
    { label: 'Scheduled', desc: 'The quiz will be available at a specified date and time' }
  ];

  const [formReleaseSelectedIndex, setFormReleaseSelectedIndex] = useState(null);
  const [quizTypeSelectedIndex, setQuizTypeSelectedIndex] = useState(null);
  const [value, onChange] = useState(moment().minutes(10).seconds(0));

  const dispatch = useDispatch()
  const { duration } = useSelector(state => state.quiz)

  useEffect(() => {
    onChange(moment().minutes(duration / 60).seconds(duration % 60));
  }, [duration])

  const toggleOptions = [
    {
      label: 'Missed questions',
      desc: 'Respondents can see which questions were answered incorrectly'
    },
    {
      label: 'Correct answers',
      desc: 'Respondents can see correct answers after grades are released'
    },
    {
      label: 'Point values',
      desc: 'Respondents can see total points and points received for each question'
    },
    {
      label: 'Default question point value',
      desc: 'Point values for every new question'
    }
  ];

  const toggleOptions2 = [
    {
      label: 'Email notification',
      desc: 'Receive an instant notification when a new response is submitted to your form'
    },
    {
      label: 'Edit after Completed',
      desc: 'If you’re using tracking this will allow your participants to edit their response after it has already been fully completed'
    },
    {
      label: 'Prevent duplicate responses',
      desc: 'Limit number of responses per computer to one using cookies'
    }
  ];

  const [toggleStates, setToggleStates] = useState(Array(toggleOptions.length).fill(false));

  const [toggleStates2, setToggleStates2] = useState(Array(toggleOptions2.length).fill(false));

  const handleToggleChange = (index) => {
    const updatedStates = [...toggleStates];
    updatedStates[index] = !updatedStates[index];
    setToggleStates(updatedStates);
  };

  const handleToggleChange2 = (index) => {
    const updatedStates = [...toggleStates2];
    updatedStates[index] = !updatedStates[index];
    setToggleStates2(updatedStates);
  };

  const handleTimeChange = (newValue) => {
    onChange(newValue);
    const totalSeconds = newValue.minutes() * 60 + newValue.seconds();
    dispatch(setQuizDuration(totalSeconds))
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>General Settings</h1>
      <div className={styles.section}>
        <div className={styles.rowContainer}>
          <p className={styles.subtitle}>Associated Course</p>
          <CustomDropdown
            extraClass="settingsDropdown"
            list={COUNTRIES_BY_CONTINENTS}
            placeholder="Mental Health"
          />
        </div>

        <div className={styles.rowContainer}>
          <p className={styles.subtitle}>Associated Lesson</p>
          <CustomDropdown
            extraClass="settingsDropdown"
            list={COUNTRIES_BY_CONTINENTS}
            placeholder="Search or select a lesson or topic"
            search
          />
        </div>

        <div className={`${styles.rowContainer} ${styles.rowStart}`}>
          <p className={styles.subtitle}>Form release schedule</p>
          <div className={styles.options}>
            {options.map((option, index) => (
              <div key={index} className={styles.radioInput}>
                <CustomRadioButton
                  checked={formReleaseSelectedIndex === index}
                  onChange={() => setFormReleaseSelectedIndex(index)}
                />
                <div className={styles.option}>
                  <label className={styles.radioLabel}>{option.label}</label>
                  <p className={styles.desc}>{option.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.rowRadio}>
          <CustomRadioButton
            checked={quizTypeSelectedIndex === 0}
            onChange={() => setQuizTypeSelectedIndex(0)}
          />
          <div className={styles.option}>
            <label className={styles.radioLabel}>Make this a quiz</label>
            <p className={styles.desc}>
              Assign points values, set answers and automatically provide feedback
            </p>
          </div>

          <CustomRadioButton
            extraClass="surveyRadio"
            checked={quizTypeSelectedIndex === 1}
            onChange={() => setQuizTypeSelectedIndex(1)}
          />
          <div className={styles.option}>
            <label className={styles.radioLabel}>Make this a survey</label>
            <p className={styles.desc}>Provide feedback from courses</p>
          </div>
        </div>

        <p className={styles.subtitle}>Respond Settings</p>
        <div className={styles.respondSettings}>
          {toggleOptions.map((option, index) => (
            <div className={styles.rowContainer} key={index}>
              <div className={styles.option}>
                <label className={styles.radioLabel}>{option.label}</label>
                <p className={styles.desc}>{option.desc}</p>
              </div>
              <Toggle checked={toggleStates[index]} onChange={() => handleToggleChange(index)} />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.section}>
        <h1 className={styles.responsesTitle}>Responses</h1>
        <div className={styles.respondSettings}>
          <p className={styles.subtitle}>Respond Settings</p>
          {toggleOptions2.map((option, index) => (
            <div className={styles.rowContainer} key={index}>
              <div className={styles.option}>
                <label className={styles.radioLabel}>{option.label}</label>
                <p className={styles.desc}>{option.desc}</p>
              </div>
              <Toggle checked={toggleStates2[index]} onChange={() => handleToggleChange2(index)} />
            </div>
          ))}
          <div className={styles.rowContainer}>
            <p className={styles.subtitle}>Time limit</p>
            <TimePicker
              value={value}
              onChange={handleTimeChange}
              showHour={false}
              showSecond={true}
              format="mm:ss"
              use12Hours={false}
              inputReadOnly
              minuteStep={1}
              secondStep={1}
              placeholder="00:00"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizSettings;
