import { createSlice } from '@reduxjs/toolkit';
import { randomUUId } from 'helpers/general';

const initialState = {
  course: {
    id: randomUUId(),
    chapters: []
  }
};

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    addChapter(state, action) {
      state.course.chapters.push(action.payload);
    },
    renameItem(state, action) {
      const { itemId, newTitle, itemType } = action.payload;
      const findAndRename = (items) => {
        const item = items.find((item) => item.id === itemId);
        if (item) item.title = newTitle;
      };

      if (itemType === 'chapter') {
        findAndRename(state.course.chapters);
      } else if (itemType === 'lesson') {
        state.course.chapters.forEach((chapter) => findAndRename(chapter.lessons));
      } else if (itemType === 'content') {
        state.course.chapters.forEach((chapter) => {
          chapter.lessons.forEach((lesson) => findAndRename(lesson.content));
        });
      }
    },
    addLesson(state, action) {
      const { chapterId, lesson } = action.payload;
      const chapter = state.course.chapters.find((c) => c.id === chapterId);
      if (chapter) {
        chapter.lessons.push(lesson);
      }
    },
    addContent(state, action) {
      const { lessonId, content } = action.payload;
      state.course.chapters.forEach((chapter) => {
        const lesson = chapter.lessons.find((l) => l.id === lessonId);
        if (lesson) {
          lesson.content.push(...content);
        }
      });
    },
    deleteItem(state, action) {
      const { itemId, itemType } = action.payload;

      const filterItems = (items) => items.filter((item) => item.id !== itemId);

      if (itemType === 'chapter') {
        state.course.chapters = filterItems(state.course.chapters);
      } else if (itemType === 'lesson') {
        state.course.chapters.forEach((chapter) => {
          chapter.lessons = filterItems(chapter.lessons);
        });
      } else if (itemType === 'content') {
        state.course.chapters.forEach((chapter) => {
          chapter.lessons.forEach((lesson) => {
            lesson.content = filterItems(lesson.content);
          });
        });
      }
    }
  }
});

export const { addChapter, renameItem, addLesson, addContent, deleteItem } = courseSlice.actions;
export default courseSlice.reducer;
