import React, { useEffect, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './AboutCourse.module.scss';
import { skillSuggestions } from 'utils/skills';
import UploadArea from '../../../../../molecules/UploadArea/UploadArea';
import CourseDetails from '../CourseDetails/CourseDetails';

const AboutCourse = () => {
    const [skills, setSkills] = useState([]);
    const [skillInput, setSkillInput] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [imageUrl, setImageUrl] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);

    const suggestionRef = useRef(null);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/*',
        multiple: false,
        onDrop: handleImageDrop,
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    function handleImageDrop(acceptedFiles) {
        const file = acceptedFiles[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setImageUrl(reader.result);
            reader.readAsDataURL(file);
        }
    }

    function handleAddSkill(skill) {
        if (skill && !skills.includes(skill)) {
            setSkills([...skills, skill]);
            resetSkillInput();
        }
    }

    function resetSkillInput() {
        setSkillInput('');
        setFilteredSuggestions([]);
        setShowSuggestions(false);
    }

    function handleInputChange(e) {
        const input = e.target.value;
        setSkillInput(input);
        if (input) {
            const filtered = skillSuggestions.filter((skill) =>
                skill.toLowerCase().startsWith(input.toLowerCase())
            );
            setFilteredSuggestions(filtered);
            setShowSuggestions(filtered.length > 0);
        } else {
            resetSkillInput();
        }
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter' && skillInput.trim()) {
            handleAddSkill(skillInput.trim());
        }
    }

    function removeSkill(indexToRemove) {
        setSkills(skills.filter((_, index) => index !== indexToRemove));
    }

    const uploadAreaStyle = {
        backgroundImage: imageUrl
            ? `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${imageUrl})`
            : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('https://res.cloudinary.com/dsg2ktuqk/image/upload/c_scale,w_600/v1728897073/Templates/remy_loz-3S0INpfREQc-unsplash_iwa1nd.webp')`,
    };

    return (
        <div className={styles.uploadContainer}>
            <UploadArea getRootProps={getRootProps} getInputProps={getInputProps} isDragActive={isDragActive} style={uploadAreaStyle} />
            <CourseDetails
                skills={skills}
                skillInput={skillInput}
                showSuggestions={showSuggestions}
                filteredSuggestions={filteredSuggestions}
                suggestionRef={suggestionRef}
                onInputChange={handleInputChange}
                onKeyPress={handleKeyPress}
                onAddSkill={handleAddSkill}
                onRemoveSkill={removeSkill}
            />
        </div>
    );
};

export default AboutCourse;
