import React, { useCallback, useState } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, Slate } from 'slate-react';
import { createEditor } from 'slate';
import { Toolbar } from './Components';
import { toggleMark } from './Helpers';
import Element from './Element';
import Leaf from './Leaf';
import { HOTKEYS } from './constants';
import MarkButton from './MarkButton';
import BlockButton from './BlockButton';
import styles from './MWEditor.module.scss';
import Bold from '../../../assets/icons/bold.svg?react';
import NumList from '../../../assets/icons/listicon.svg?react';
import BulletList from '../../../assets/icons/bullet.svg?react';
import Italic from '../../../assets/icons/italicicon.svg?react';
import Left from '../../../assets/icons/lefticon.svg?react';
import Center from '../../../assets/icons/centericon.svg?react';
import Underline from '../../../assets/icons/underlineee.svg?react';
import Right from '../../../assets/icons/righticon.svg?react';

const MWEditorLite = ({ placeholderText, data, setData, ...rest }) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const [editor] = useState(() => withReact(createEditor()), []);

  return (
    <div className={styles.container}>
      <Slate
        editor={editor}
        value={data ? data : initialValue}
        onChange={() => {
          setData(editor.children);
        }}
      >
        <Toolbar>
          <MarkButton format="bold" icon={<Bold />} className={styles.editorIcon} tooltip="Bold" />
          <MarkButton
            format="italic"
            icon={<Italic />}
            className={styles.editorIcon}
            tooltip="Italic"
          />
          <MarkButton
            format="underline"
            icon={<Underline />}
            className={styles.editorIcon}
            tooltip="Underline"
          />
          {/* <BlockButton
            format="heading-one"
            icon={<H1 />}
            className={styles.editorIcon}
            tooltip="H1"
          />
          <BlockButton
            format="block-quote"
            icon={<Quote />}
            className={styles.editorIcon}
            tooltip="Quote"
          /> */}
          <BlockButton
            format="numbered-list"
            icon={<NumList />}
            className={styles.editorIcon}
            tooltip="Numbered list"
          />
          <BlockButton
            format="bulleted-list"
            icon={<BulletList />}
            className={styles.editorIcon}
            tooltip="Bulleted list"
          />
          <BlockButton
            format="left"
            icon={<Left />}
            className={styles.editorIcon}
            tooltip="Align left"
          />
          <BlockButton
            format="center"
            icon={<Center />}
            className={styles.editorIcon}
            tooltip="Align center"
          />
          <BlockButton
            format="right"
            icon={<Right />}
            className={styles.editorIcon}
            tooltip="Align right"
          />
        </Toolbar>
        <Editable
          {...rest}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholderText}
          spellCheck
          autoFocus
          className={`${styles.editor} ${styles.open}`}
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Slate>
    </div>
  );
};

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];
export default MWEditorLite;
