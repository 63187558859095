import { useEffect, useRef, useState } from "react";
import styles from './ContentItem.module.scss'
import { FaPencil, FaRegTrashCan } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { deleteItem, renameItem } from "store/course.slice";
import { IoEllipsisVertical, } from "react-icons/io5";
import FilePreview from "components/molecules/FilePreview/FilePreview";
import { extIcon } from "helpers/course";

const ContentItem = ({ item }) => {
    const [openMenuId, setOpenMenuId] = useState(null);
    const [editable, setEditable] = useState(false)
    const [openPreview, setOpenPreview] = useState(false)
    const contentMenuRef = useRef(null);
    const dispatch = useDispatch();

    const { id, fileDetails, type, title: contentTitle } = item


    useEffect(() => {
        const getClassName = (event) => {
            const { className } = event.target;
            return className instanceof SVGAnimatedString ? className.baseVal : className;
        };

        const handleClickContentMenuOutside = (event) => {
            const className = getClassName(event);

            if (contentMenuRef.current && !contentMenuRef.current.contains(event.target)) {
                if (className !== "hideMenu") {
                    setOpenMenuId(null);
                }
            }
        };

        document.addEventListener('click', handleClickContentMenuOutside, true);

        return () => {
            document.removeEventListener('click', handleClickContentMenuOutside, true);
        };
    }, []);

    const openPreviewModal = (content) => {
        setOpenPreview(true);
    };

    const closePreviewModal = () => {
        setOpenPreview(false);
    };

    const toggleContentMenu = (id) => {
        setOpenMenuId((prev) => (prev === id ? null : id));
    };

    const handleContentRename = (id) => {
        setEditable(true)
        setOpenMenuId(null);
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            setEditable(false)
        }
    }

    const handleContentDelete = (id) => {
        dispatch(deleteItem({ itemId: id, itemType: 'content' }))
        setOpenMenuId(null);
    }

    const handleTitleChange = (e) => {
        const title = e.target.value
        dispatch(renameItem({ itemId: id, itemType: 'content', newTitle: title }))
    }

    const ext = type !== 'link' ? (fileDetails?.extension || "") : "link";
    const previewContent = {
        path: item.source.path,
        type: item.type,
        title: item.title,
        ext: item.fileDetails.extension
    }    

    return (
        <div key={id} className={styles.item}>
            <div className={styles.left}>
                {extIcon(ext)}
                {
                    editable ?
                        <input
                            type="text"
                            value={contentTitle}
                            onChange={handleTitleChange}
                            onBlur={() => setEditable(false)}
                            onKeyUp={handleKeyUp}
                            autoFocus
                        />
                        :
                        <div className={styles.title}>{contentTitle}</div>
                }
            </div>
            <div className={styles.actions}>
                <button
                onClick={openPreviewModal}
                >
                    Preview
                </button>
                <div className={styles.icon} key={id}>
                    <button className={'hideMenu'} onClick={(e) => {
                        e.preventDefault()
                        toggleContentMenu(id)
                    }}>
                        <IoEllipsisVertical className="hideMenu" />
                    </button>
                    {openMenuId === id && (
                        <div className={styles.contextmenu} ref={contentMenuRef}>
                            <div
                                className={styles.option}
                                onClick={() => handleContentRename(id)}
                            >
                                <div className={styles.option_icon}>
                                    <FaPencil />
                                </div>
                                <div className={styles.option_title}>
                                    Rename
                                </div>
                            </div>
                            <div
                                className={styles.option}
                                onClick={() => handleContentDelete(item.id)}
                            >
                                <div className={styles.option_icon}>
                                    <FaRegTrashCan />
                                </div>
                                <div className={styles.option_title}>
                                    Delete
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>            
            <FilePreview isOpen={openPreview} onClose={closePreviewModal} content={previewContent} />
        </div>
    )
}

export default ContentItem;
