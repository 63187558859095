import React, { useState } from 'react';
import NotesEditor from './Editor';
import styles from '../../../styles.module.scss';
import style from './styles.module.scss';

export default function Index() {
  const [notes, setNotes] = useState([]);
  const [data, setData] = useState(null);

  const handleAddNote = (newNote) => {
    setNotes([newNote, ...notes]);
    setData('');
  };

  return (
    <div>
      <h1 className={styles.sectionTitle}>Notes ({notes.length})</h1>
      <NotesEditor
        placeholderText="Add a note..."
        data={data}
        setData={setData}
        onAddNote={handleAddNote}
      />
      <div className={style.notesContainer}>
        {notes.map((note, index) => (
          <div key={index} className={style.note}>
            {note.map((block, i) => (
              <div
                key={i}
                style={{
                  textAlign: block.align || 'left'
                }}
              >
                {block.children.map((leaf, j) => (
                  <span
                    key={j}
                    style={{
                      fontWeight: leaf.bold ? 'bold' : 'normal',
                      fontStyle: leaf.italic ? 'italic' : 'normal',
                      textAlign: block.align || 'left'
                    }}
                  >
                    {leaf.text}
                  </span>
                ))}
                {block.type === 'image' && <img src={block.url} alt="media" />}
                {block.type === 'video' && (
                  <video controls>
                    <source src={block.url} type="video/mp4" />
                  </video>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
